import React from "react";
import { useLocation } from "react-router-dom";
import routes from "@/common/routes";
import ApplicationModal from "@/module/applications/Modals";
import ComplianceModal from "@/module/compliance/Modals";
import OnboardingModal from "@/module/onboarding/Modals";
import SettingsModal from "@/module/settings/Modals";
import WalletModal from "@/module/wallet/Modals";
import UserModal from "@/module/user/Modals";
import UtilityModal from "@/module/utility/Modal";
import WhitelabelModal from "@/module/whitelabel/modals";
import VirtualCardModal from "@/module/virtualcards/Modal";

const isSettingPath = (path: string) => {
  return (
    path === routes.protected.settings.two_factor_confirm_password_update ||
    path === routes.protected.settings.two_factor_toggle ||
    path === routes.protected.settings.password_confrim ||
    path === routes.protected.settings.two_factor_prompt ||
    path === routes.protected.settings.logout
  );
};

function Modal() {
  const { pathname } = useLocation();
  const settings = isSettingPath(pathname);

  // useEffectOnce(() => {
  //   return () => {
  //     cancelPendingRequests();
  //   };
  // });
  return (
    <>
      {pathname.includes("wallet") ? <WalletModal /> : null}
      {pathname.includes("utility") ? <UtilityModal /> : null}
      {pathname.includes("app") && !pathname.includes("wallet") ? (
        <ApplicationModal />
      ) : null}
      {pathname.includes("compliance") ? <ComplianceModal /> : null}
      {pathname.includes("white-label") ? <WhitelabelModal /> : null}
      {pathname.includes("onboarding") ? <OnboardingModal /> : null}
      {settings ? <SettingsModal /> : null}
      {pathname.includes("/user/") ? <UserModal /> : null}
      {pathname.includes("virtual-card") ? <VirtualCardModal /> : null}
    </>
  );
}

export default Modal;
