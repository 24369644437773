import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import CenterModal from "@/common/components/CustomModal/CenterModal";
import CustomButton from "@/common/components/CustomButton";
import { rescindInvitation } from "../../store/thunk";
import { iHandleSubmit } from "../../types";
import Language from "@/common/utils/language/en";

const { rescind_invite: PageDictionary } = Language.protected.UserModal;

function RescindUserInvite() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { not_verified_loading: loading } = useAppSelector(
    (state) => state.user
  );

  const email = location.state?.data?.email;

  const cancelHandler = () => {
    if (loading) return;
    navigate(location.state.background.pathname, {
      replace: true,
      state: null,
    });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!email) return;
    dispatch(rescindInvitation({ email })).unwrap().finally(cancelHandler);
  };
  return (
    <CenterModal className="pt-26 pb-32" handleClose={cancelHandler}>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-blue_gray-10 text-2xl">{PageDictionary.title}</h3>
        <p className="text-sm text-red-40">
          {PageDictionary.form.warning_text(email)}
        </p>

        <div className="flex items-center gap-x-9">
          <CustomButton
            disabled={loading}
            variant
            type="button"
            onClick={cancelHandler}
          >
            {PageDictionary.form.cancel}
          </CustomButton>
          <CustomButton isloading={loading}>
            {PageDictionary.form.accept}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default RescindUserInvite;
