import { FieldTypes } from "@/common/components/Form/type";
import { transferFormType } from "./schema";
import { PricingPolicyType } from "@/module/applications/types";

export const transferFormFeilds: FieldTypes<transferFormType>[] = [
  { name: "value_type", label: "Select Pricing Type", type: "select" },
  {
    name: "with_cap",
    label: "Select Pricing Condition",
    type: "select",
  },
  {
    name: "upper_cap",
    label: "Upper Cap",
    otherInfo:
      "Where markup is more than “Upper cap”, Upper cap fee will be charged as flat rate",
    suffixIcon: "$",
  },
  {
    name: "lower_cap",
    label: "Floor Cap",
    otherInfo:
      "Where markup is lesser than “floor cap”, Floor cap fee will be charged as flat rate",
    suffixIcon: "$",
  },
  {
    name: "finswich_fee",
    label: "Finswich Fee",
    otherInfo: "What you will be charged by Finswich",
  },
  { name: "markup", label: "Your Mark-up Fee", suffixIcon: "%" },
  {
    name: "service_fee",
    label: "Service Fee",
    suffixIcon: "%",
    otherInfo: "This will be charged additional to 'Mark-up Fee'",
  },
];

export const defaultValues: transferFormType = {
  value_type: "PERCENTAGE",
  with_cap: "true",
  upper_cap: 0,
  lower_cap: 0,
  finswich_fee: 0,
  markup: 0,
  service_fee: 0,
};

const pricingTypeOptions = [
  { label: "Percentage Rate", value: "PERCENTAGE" },
  { label: "Flat Rate", value: "FLAT" },
];
const pricingConditonOptions = [
  { label: "Fee with Cap", value: "true" },
  { label: "Fee with No Cap", value: "false" },
];

export const optionsHandler = (name: keyof transferFormType) => {
  if (name === "value_type") {
    return pricingTypeOptions;
  }
  if (name === "with_cap") {
    return pricingConditonOptions;
  }
  return [];
};

export const defaultValueHandler = (data?: Partial<PricingPolicyType>) => {
  return {
    ...(data || defaultValues),
    with_cap:
      typeof data?.with_cap === "boolean"
        ? JSON.stringify(data?.with_cap)
        : defaultValues.with_cap,
  };
};
