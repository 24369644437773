export const wrapText = (text: string, maxCharsPerLine: number) => {
  const words = text.split(" ");
  let lines = [];
  let currentLine = "";

  for (let word of words) {
    // Check if adding the next word would exceed max chars per line
    if ((currentLine + word).length <= maxCharsPerLine) {
      currentLine += `${word} `;
    } else {
      lines.push(currentLine.trim());
      currentLine = `${word} `;
    }
  }

  lines.push(currentLine.trim());
  return lines;
};

export const toSentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const toCapitalize = (str: string) => {
  if (!str) return "";
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};
