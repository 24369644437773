import React from "react";
import { useAppDispatch, useAppSelector, useEffectOnce } from "@/common/hooks";
import { appActions } from "../../../store/slice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Language from "@/common/utils/language/en";
import CustomTable from "@/common/components/CustomTable";
import { tableHeader } from "@/common/utils/helpers/tableHeaders";
import CustomButton from "@/common/components/CustomButton";
import routes from "@/common/routes";
import { getAppTransaction, getTransaction } from "../../../store/thunk";
import SideModal from "@/common/components/CustomModal/SideModal";

const { Transaction_information: PageDictionary } =
  Language.protected.ApplicationPages;

const {
  general: { columns: general_col },
  beneficiary: { columns: beneficiary_col },
  customer: { columns: customer_col },
} = tableHeader.transaction_information;

export function TransactionInformation() {
  const location = useLocation();
  const { state } = location;
  const { appid, txnid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selected_transaction } = useAppSelector((state) => state.app);
  const data = selected_transaction?.data;
  const loading = selected_transaction?.loading;

  useEffectOnce(() => {
    if (!appid || !txnid) return;
    if (
      state?.pathname?.includes("wallet") ||
      state?.pathname.includes("utility")
    ) {
      dispatch(getAppTransaction({ appid, txnid }));
    } else {
      dispatch(getTransaction({ appid, txnid }));
    }

    return () => {
      dispatch(appActions.removeTransaction());
    };
  });

  const handleBack = () => {
    if (loading) return;
    navigate(state?.pathname, { replace: true });
  };

  const reportHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!appid || !txnid) return;
    const path =
      routes.protected.applications.app.transactions.report_transaction.link(
        appid,
        txnid
      );
    navigate(path, {
      state: {
        background: location.state.background,
        pathname: state?.pathname,
      },
      replace: true,
    });
  };

  return (
    <SideModal
      onclickBackDrop={handleBack}
      className="relative p-8 md:pl-14 md:pr-28 md:pt-20"
    >
      <header className="flex flex-col gap-y-6 pb-6">
        <h2 className="text-2132 font-semibold">{PageDictionary.title}</h2>
      </header>
      <section className="no-scrollbar::-webkit-scrollbar no-scrollbar flex h-full flex-1 flex-col gap-y-20 overflow-y-scroll">
        <CustomTable
          title={PageDictionary.table_titles.general}
          cols={general_col}
          rows={data}
          layout_type="slate"
        />
        <CustomTable
          title={PageDictionary.table_titles.customer}
          cols={customer_col}
          rows={data?.app_consumer}
          layout_type="slate"
        />
        {data?.category !== "utility" ? (
          <CustomTable
            title={PageDictionary.table_titles.beneficiary}
            cols={beneficiary_col}
            rows={data}
            layout_type="slate"
          />
        ) : null}
        <CustomButton
          onClick={reportHandler}
          className="mb-20 self-center bg-transparent text-red-10"
        >
          {PageDictionary.report_btn}
        </CustomButton>
      </section>
    </SideModal>
  );
}
