import numeral from "numeral";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import { ValueOf, igenInput } from "../../types";
export * from "./date";
export * from "./texts";
export * from "./validation";

export const addCommas = (num: string) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const removeNonNumeric = (num: string) =>
  num.toString().replace(/[^0-9]/g, "");
export const removeCommas = (num: string) =>
  num.toString().replace(/[, ]+/g, "").trim();

export const tW = (...className: clsx.ClassValue[]) => {
  return twMerge(clsx(...className));
};
export const mergeClassName = (...className: clsx.ClassValue[]) => {
  return twMerge(clsx(...className));
};

export const switchBg = (str: string) => {
  let temp: string;

  switch (str) {
    case "funding":
    case "set_1":
      temp = "bg-teal-20";
      break;

    default:
      temp = "bg-gray-370";
      break;
  }

  return temp;
};

export const toCurrency = (
  amount: number,
  language = "en-US",
  currency = "USD"
) => {
  const curr_amount = amount.toLocaleString(language, {
    style: "currency",
    currency,
  });
  return curr_amount;
};

export const FormatNumber = (
  value: number | string,
  curr?: boolean
): string => {
  if (typeof value === "string") value = Number(value);

  let currency = curr ? "$" : "";
  return currency + numeral(value).format("0,0.00");
};
export const returnFormatNumber = (
  value: number | string,
  curr?: boolean
): string => {
  if (typeof value === "string") value = Number(value);

  let currency = curr ? "$" : "";
  return currency + numeral(value).format();
};
export const FormatPecentage = (value: number | string): string => {
  if (typeof value === "string") value = Number(value);

  return numeral(value).format("0%");
};

export const ParsePercentage = (value: string): number => {
  // Remove the percentage sign and convert to a number
  const parsedValue = numeral(value.replace("%", "")).value();

  // If parsed value is not a number, return 0 (or handle as needed)
  return parsedValue !== null ? parsedValue / 100 : 0; // Divide by 100 to convert to a decimal
};

export const ParseFormattedNumber = (value: string): number => {
  const cleanedValue = value.replace(/[,$]/g, "");
  return parseFloat(cleanedValue);
};

export const greetHandler = (day: Date, dic: any) => {
  return day.getHours() < 12
    ? dic.morning
    : 12 >= day.getHours() && day.getHours() <= 16
    ? dic.afternoon
    : dic.evening;
};

export const dateHandler = (date: Date): string => {
  return date
    .toDateString()
    .split(" ")
    .map((itm, idx) => {
      if (idx === 0) {
        return `${itm}day,`;
      }
      return itm;
    })
    .join(" ");
};

export const mockResponseHandler = async (
  mockSuccess: any,
  mockError: any,
  data?: any
): Promise<any> => {
  if (data) {
    if (Object.values(data).includes("") === false) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(mockSuccess);
        }, 3000);
      });
    }
    if (Object.values(data).includes("") === true) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(mockError);
        }, 3000);
      });
    }
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockSuccess);
    }, 3000);
  });
};

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getBankFieldsInitialState = (
  arr: any[]
): Record<string, igenInput> => {
  const res: any = {};
  arr.forEach((itm) => {
    res[itm.name] = { value: "", error: false };
  });

  return res;
};

export const removeSpaceAddUnderScore = (str: string): string =>
  str.split(" ").join("_");
export const removeUnderScoreAddSpace = (str: string): string =>
  str?.split("_")?.join(" ");

export const lastPathRoute = (str: string): string => {
  const split_string = str.split("/");
  return split_string[split_string.length - 1];
};

export const convertBankFieldsAndRules = (arr: any[]) => {
  const res: any[] = [];

  arr.forEach((itm) => {
    const temp: any = {};
    temp.title = removeUnderScoreAddSpace(itm.name).toLowerCase();
    temp.rules = `^.{${itm.min_length},${itm.max_length}}$`;
    temp.name = itm.name;
    temp.value_type = itm.value_type;
    temp._id = itm._id;
    temp.is_required = itm.is_required;
    res.push(temp);
  });

  return res;
};

export const formatBankTable = (item: any) => {
  const cols: any[] = [];
  const row: any = { ...item };

  item?.fields?.forEach((itm: any) => {
    const title = removeUnderScoreAddSpace(itm.name);
    cols.push({ title, dataIndex: "", key: itm.name });
    row[itm.name] = itm.value;
  });

  return {
    cols,
    rows: [row],
  };
};

export function groupDataByKey(data: any[], key: string) {
  const groupedData: any = {};

  // Group the data by day
  data.forEach((item) => {
    if (!groupedData[item[key]]) {
      groupedData[item[key]] = [];
    }
    groupedData[item[key]].push(item);
  });

  // Convert the object to an array
  // const result: any[] = Object.values(groupedData);

  return groupedData;
}

export function dataDifference(state: any, data?: any) {
  const statekeys = Object.keys(state); /* as Array<keyof typeof state> */

  let difference: Record<string, ValueOf<typeof state>> = {};
  let count = 0;

  if (!data) return { difference, count };

  statekeys.forEach((key) => {
    if (JSON.stringify(state[key]) !== JSON.stringify(data[key])) {
      count++;
      difference[key] = state[key];
    }
  });

  return {
    difference,
    count,
  };
}

export const truncatedText = (text?: string, maxlength?: number) => {
  if (!text || !maxlength) return "";
  const minLength = Math.round((maxlength - 3) / 2);
  if (text?.length > maxlength) {
    return `${text?.substring(0, minLength)}...${text?.substring(
      text?.length - minLength
    )}`;
  }

  return text;
};
export const truncatedTextEnd = (text?: string, maxlength?: number) => {
  if (!text || !maxlength) return "";
  if (text?.length > maxlength) {
    return `${text?.substring(0, maxlength)}..`;
  }

  return text;
};

// export const pageAccessedByReload =
//   (window.performance.navigation && window.performance.navigation.type === 1) ||
//   window.performance
//     ?.getEntriesByType("navigation")
//     ?.map((nav) => nav?.entryType)
//     ?.includes("reload");

export async function convertBase64ToPNG(
  base64String: string,
  fileName: string
): Promise<File> {
  const response = await fetch(base64String);
  const blob = await response.blob();

  const file = new File([blob], fileName, { type: "image/jpeg" });

  return file;

  // const link = document.createElement("a");
  // link.href = URL.createObjectURL(blob);
  // link.download = fileName;
  // link.click();
}

export function convertSVGToBase64(svg: string): Promise<string> {
  const svgData = new Blob([svg], { type: "image/svg+xml" });
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Failed to convert SVG to base64 string."));
      }
    };

    reader.onerror = reject;
    reader.readAsDataURL(svgData);
  });
}

export const downloadExcel = (
  data: string | any,
  type?: "json" | "text",
  title?: string
) => {
  // console.log(data, "data");
  let ws: XLSX.WorkSheet;
  if (type === "json") {
    ws = XLSX.utils.json_to_sheet(data.trim());
  } else {
    ws = XLSX.utils.aoa_to_sheet(
      data.split("\n").map((row: string) => row.split(","))
    );
  }
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "file" });

  if (excelBuffer) {
    saveAs(excelBuffer, title ? `${title}.xlsx` : "excel-sheet.xlsx");
  }
};

export const exportToExcel = (exportData: any, title: string) => {
  // console.log(exportData, "exportTOExcel");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX?.utils?.json_to_sheet(exportData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX?.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  // console?.log(data);
  FileSaver?.saveAs(data, title + fileExtension);
};

export const exportToCSV = (data: any, name?: string) => {
  const cleanData = data.replace(/\s+/g, ""); // Removes all whitespace
  const blob = new Blob([cleanData], { type: "text/csv;charset=utf-8" });
  saveAs(blob, `${name ? name : "data"}.csv`);
};

export const downloadExcelStream = (
  base64String: ArrayBuffer,
  title: string
) => {
  const temp = new Blob([base64String], {
    type: "application/vnd.ms-excel",
  });

  const blob = temp;

  // Create a download link and trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${title}.xls`; // Set the desired file name with .xls extension
  link.click();

  URL.revokeObjectURL(link.href);
};

export function differenceHandler<T extends object>(
  obj1: T,
  obj2?: Partial<T>
): Partial<T> {
  const difference: any = {};

  const keys = Object.keys(obj1) as Array<keyof T>;

  keys.forEach((key) => {
    if (
      JSON.stringify(obj1[key]) !== JSON.stringify(obj2?.[key]) ||
      !obj2?.[key]
    ) {
      difference[key] = obj1[key];
    }
  });

  return difference;
}
