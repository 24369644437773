import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "@/common/components/CustomButton";
import CustomInput from "@/common/components/CustomInput";
import SideModal from "@/common/components/CustomModal/SideModal";
import routes from "@/common/routes";
import ModalTitleHeader from "@/common/components/ModalTitleHeader";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import {
  iHandleChange,
  iHandleSubmit,
  IwithrawCrypto,
} from "../../../../types";
import { bankWithdrawal } from "../../../../store/thunk";
import { FormatNumber, removeCommas } from "@/common/utils/helpers";
import ModalNavLinkWithdrawal from "../../../../components/ModalNavLinkWithdrawal";
import Language from "@/common/utils/language/en";

const { AuthorizeWithdrawal: PageDictionary } =
  Language.protected.walletModals.withdrawal;

const formatCyptoData = (data?: IwithrawCrypto, amount?: string) => {
  const temp: any[] = [];

  if (!data || !amount) return;

  temp.push({ title: "Amount", value: amount });
  (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
    let k_data: any = {};

    switch (key) {
      case "address":
      case "network":
        k_data = { title: key, value: data[key] };
        break;
      case "crypto_symbol":
        k_data = { title: "Coin", value: data[key] };
        break;

      default:
        break;
    }
    if (k_data.title) {
      temp.push(k_data);
    }
  });

  return temp;
};

function AuthorizeCrypto() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation().state;
  const background = location?.background;
  const amount = location?.amount;
  const { crypto } = useAppSelector((state) => state.wallet.withdrawal.data);
  const { loading } = useAppSelector((state) => state.wallet.app_wallet);
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState("");
  const temp_crypto = crypto?._id ? crypto : undefined;

  const data = formatCyptoData(temp_crypto, FormatNumber(amount, true));

  const backHandler = () => {
    if (!appid || loading) return;
    const path = routes.protected.applications.app.wallet.withdrawl.link(appid);
    navigate(path, { state: { background, amount, isCrypto: true } });
  };

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value } = e.target;
    setPassword(value);
  }, []);

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();

    if (!appid) return;
    const success_path =
      routes.protected.applications.app.wallet.withdrawl.success.link(appid);
    const error_path = background?.pathname;

    const req = {
      appid,
      data: {
        amount: removeCommas(amount),
        password,
        withdrawal_mode: "CRYPTO",
      },
    };

    dispatch(bankWithdrawal(req))
      .unwrap()
      .then(() => {
        navigate(success_path, {
          state: { background },
        });
      })
      .catch(() => {
        navigate(error_path, {
          state: { background },
        });
      });
  };
  return (
    <SideModal
      onclickBackDrop={backHandler}
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <ModalTitleHeader
        title={PageDictionary.title}
        subtitle={PageDictionary.subtitle}
      />
      <div className="mt-9">
        <ModalNavLinkWithdrawal amount={amount} />
      </div>

      <div className="grid grid-cols-2 gap-y-7 py-12">
        {data?.map((itm) => (
          <div className="flex flex-col gap-y-1.5" key={itm?.title}>
            <div className="text-sm leading-6 text-base-20">{itm?.title}</div>
            <div className="text-lg font-bold leading-6 text-black">
              {itm?.value}
            </div>
          </div>
        ))}
      </div>

      <form className="flex flex-col gap-y-10 pt-5" onSubmit={submitHandler}>
        <CustomInput
          label={PageDictionary.form.password_label}
          value={password}
          onChange={changeHandler}
          type="password"
        />

        <CustomButton className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6">
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default AuthorizeCrypto;
