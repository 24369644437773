import React from "react";

const OtherInfo = ({ data }: { data?: string }) => {
  return (
    <>
      {data ? (
        <p className="text-sm font-medium italic text-[#090914]">{data}</p>
      ) : null}
    </>
  );
};

export default OtherInfo;
