import { useState } from "react";
import { Select, SelectItem } from "@/common/components/CustomSelect";

const MOCK_LIST = [
  { value: "weekly" },
  { value: "monthly" },
  { value: "yearly" },
];

export const AuditReportingSchedule = () => {
  const [selectedValue, setSelectedValue] = useState(MOCK_LIST[0].value);

  return (
    <Select
      label=""
      labelClassName="mb-0 hidden"
      className="h-auto w-full min-w-[5.25rem] max-w-[6.625rem]"
      onChange={(val) => setSelectedValue(val)}
      disabled={false}
      value={selectedValue}
      placeholder="Select a Notification method"
    >
      {MOCK_LIST.map((schedule) => (
        <SelectItem key={schedule.value} value={schedule.value}>
          <span>{schedule.value}</span>
        </SelectItem>
      ))}
    </Select>
  );
};
