import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { usePageTitle } from "@/common/hooks";
import CustomButton from "@/common/components/CustomButton";
import { FixedHorizontalNav as HorizontalNav } from "@/common/components/Layout/HorizontalNav";
import { settingNavLinks } from "@/common/utils/helpers/navHelpers";
import routes from "@/common/routes";
import Language from "@/common/utils/language/en";

const PageDictionary = Language.protected.Layout.setting_layout;

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();

  usePageTitle(PageDictionary.page_title);

  const clickHandler = () => {
    navigate(routes.protected.compliance.shareholders.add, {
      state: { background: location },
    });
  };

  return (
    <section className="flex h-screen flex-col overflow-y-scroll px-8 pt-18 pb-10 md:px-16">
      <header>
        <h3 className="select-none text-2826 font-semibold">
          {PageDictionary.page_title}
        </h3>
        <nav className="flex items-center justify-between pt-3">
          <HorizontalNav navlinks={settingNavLinks} />
          {location.pathname ===
          routes.protected.compliance.shareholders.index ? (
            <CustomButton
              onClick={clickHandler}
              className=" px-2.5 py-3 text-base leading-7"
            >
              {PageDictionary.add_shareholder}
            </CustomButton>
          ) : null}
        </nav>
      </header>
      <Outlet />
    </section>
  );
}

export default Layout;
