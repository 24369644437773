import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "@/common/components/CustomButton";
import { useAppSelector, usePageTitle } from "@/common/hooks";
import routes from "@/common/routes";
import Language from "@/common/utils/language/en";
import { fetchSessionData } from "@/common/service/storage";

const { errorPage: PageDictionary } = Language.nonprotected;

function ErrorPage() {
  usePageTitle(PageDictionary.pageTitle);
  const company = useAppSelector((state) => state.auth.company);
  const { company_id } = fetchSessionData();
  const admin_approved = company?.admin_approved;
  const terms_submited =
    company?.agreement_info?.authorizer_designation !== undefined;
  const agreement_complete = company?.agreement_info?.signature?.complete;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const renderErrorPageBtn = (path: string): string => {
    let template = "";

    switch (true) {
      case agreement_complete && admin_approved:
        template = PageDictionary.dashboard;
        break;
      case agreement_complete && !admin_approved:
      case (!terms_submited || !agreement_complete) && !!company_id:
        template = PageDictionary.onboarding;
        break;
      default:
        template = PageDictionary.login;
        break;
    }

    return template;
  };

  const clickHandler = () => {
    let path = "";
    switch (true) {
      case agreement_complete && admin_approved:
        path = routes.protected.dashboard;
        break;
      case agreement_complete && !admin_approved:
        path = routes.protected.onboarding.shareholders.index;
        break;
      case (!terms_submited || !agreement_complete) && !!company_id:
        path = routes.protected.onboarding.terms.index;
        break;

      default:
        path = routes.nonprotected.login;
        break;
    }

    navigate(path, { replace: true });
  };

  return (
    <div className="flex h-screen bg-black p-20 design_screen:mx-auto design_screen:max-w-screen-design_screen">
      <div className="flex-1 bg-black bg-errorBg bg-contain bg-right-top bg-no-repeat px-10 py-40 text-white ">
        <div className="flex w-1/3 flex-col gap-y-8">
          <header>
            <h3 className="text-[4rem] font-bold leading-none">
              {PageDictionary.heading}
            </h3>
            <h1 className="text-[3.5rem] font-normal leading-none">
              {PageDictionary.subheading}
            </h1>
          </header>

          <CustomButtom
            onClick={clickHandler}
            className="mt-20 self-start rounded-none"
          >
            {renderErrorPageBtn(pathname)}
          </CustomButtom>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
