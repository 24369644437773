import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { amountValidation } from "@/common/utils/helpers";

const transferFormSchema = z
  .object({
    value_type: z.string({ message: "Pricing type is required" }),
    with_cap: z.string({ message: "Pricing condition is required" }),
    upper_cap: amountValidation("Upper Cap")
      .optional()
      .transform((val) => (val ? Number(val) : undefined)),
    lower_cap: amountValidation("Floor Cap")
      .optional()
      .transform((val) => (val ? Number(val) : undefined)),
    finswich_fee: amountValidation("Finswich Fee").transform(Number),
    markup: amountValidation("Your Mark-up Fee").transform(Number),
    service_fee: amountValidation("Service Fee").transform(Number),
  })
  .superRefine((data, ctx) => {
    if (data.value_type === "PERCENTAGE") {
      if (data.markup > 100) {
        ctx.addIssue({
          path: ["markup"],
          message: "Markup must not exceed 100 when value type is PERCENTAGE",
          code: z.ZodIssueCode.too_big,
          maximum: 100,
          type: "number",
          inclusive: true,
        });
      }
      if (data.service_fee > 100) {
        ctx.addIssue({
          path: ["service_fee"],
          message:
            "Service Fee must not exceed 100 when value type is PERCENTAGE",
          code: z.ZodIssueCode.too_big,
          maximum: 100,
          type: "number",
          inclusive: true,
        });
      }
    }
  });

export const resolver = zodResolver(transferFormSchema);

export type transferFormType = z.infer<typeof transferFormSchema>;
