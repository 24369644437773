import { combineReducers } from "redux";
// import createMigrate from "redux-persist/es/createMigrate";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import auth from "../module/auth/store/slice";
import dashboard from "../module/dashboard/store/slice";
import app from "../module/applications/store/slice";
import compliance from "../module/compliance/store/slice";
import settings from "../module/settings/store/slice";
import wallet from "../module/wallet/store/slice";
import utility from "../module/utility/store/slice";
import user from "../module/user/store/slice";
import pagination from "./helperReducers/paginationSlice";
import appState from "./helperReducers/appStateSlice";
import { virtualCardApi } from "@/module/virtualcards/store/api";
import { collectionApi } from "@/module/paylink/service/api";

// const MIGRATE_DEBUG = false;

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["user", "token", "company"],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};
const appPersistConfig = {
  key: "app",
  storage: storage,
  blacklist: ["loading", "error"],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};
const appStatePersistConfig = {
  key: "appState",
  storage: storage,
  whitelist: ["openedTabs"],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  dashboard,
  app: persistReducer(appPersistConfig, app),
  compliance,
  settings,
  wallet,
  utility,
  user,
  pagination,
  appState: persistReducer(appStatePersistConfig, appState),
  [virtualCardApi.reducerPath]: virtualCardApi.reducer,
  [collectionApi.reducerPath]: collectionApi.reducer,
});

export default rootReducer;
