import { ENDPOINTS } from "@/common/service/config/endpoint";
import { axiosBaseQuery, queryHandler } from "@/common/service/request";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CardBalanceType,
  CardStatCountType,
  CardType,
  FreezeCardPayloadType,
  FundCardPayloadType,
  GetCardsParams,
  MerchantCardWalletType,
} from "./type";
import { APIResponseModel } from "@/common/types";
import customToast from "@/common/components/CustomToast";
const {
  baseUrl,
  LIST_CARDS,
  FREEZE_CARD,
  FUND_CARD,
  GET_CARD_COUNTS,
  GET_MERCHANT_CARD_WALLET,
  GET_TOTAL_CARD_BALANCE,
} = ENDPOINTS.virtual_card;

export const virtualCardApi = createApi({
  reducerPath: "virtual-cards",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["TotalCardBalance", "CardStatCount", "GetCards"],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getCards: builder.query<APIResponseModel<CardType[]>, GetCardsParams>({
      query: ({ appid = "", ...params }) => {
        const { url: makeUrl, method } = LIST_CARDS;
        const query = queryHandler(params);
        const url = makeUrl(appid) + query;
        return {
          url,
          method,
        };
      },
      //   onQueryStarted: async (_, { queryFulfilled }) => {
      //     const message = (await queryFulfilled).data.message;
      //     customToast.success(message);
      //   },
    }),
    getMerchantCardWallet: builder.query<
      APIResponseModel<MerchantCardWalletType>,
      string
    >({
      query: (appid?: string) => {
        const { url: makeUrl, method } = GET_MERCHANT_CARD_WALLET;
        const url = makeUrl(appid || "");
        return {
          url,
          method,
        };
      },
    }),
    getTotalCardBalance: builder.query<
      APIResponseModel<CardBalanceType>,
      string
    >({
      query: (appid: string) => {
        const { url: makeUrl, method } = GET_TOTAL_CARD_BALANCE;
        const url = makeUrl(appid);
        return {
          url,
          method,
        };
      },
      providesTags: ["TotalCardBalance"],
    }),
    getCardCounts: builder.query<APIResponseModel<CardStatCountType>, string>({
      query: (appid: string) => {
        const { url: makeUrl, method } = GET_CARD_COUNTS;
        const url = makeUrl(appid);
        return {
          url,
          method,
        };
      },
      providesTags: ["CardStatCount"],
    }),
    freezeCard: builder.mutation<any, FreezeCardPayloadType>({
      query: (query) => {
        const { url: makeUrl, method } = FREEZE_CARD;
        const { appid, ...data } = query;
        const url = makeUrl(appid);
        return {
          url,
          method,
          data,
        };
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const message = (await queryFulfilled).data.message;
        customToast.success(message);
        dispatch(
          virtualCardApi.util.invalidateTags(["CardStatCount", "GetCards"])
        );
      },
      transformErrorResponse: (response) => {
        const message = response?.data as string;
        if (typeof response.data === "string") {
          customToast.error(message);
        }
      },
    }),
    fundCard: builder.mutation<any, FundCardPayloadType>({
      query: (query) => {
        const { url: makeUrl, method } = FUND_CARD;
        const { appid, ...data } = query;
        const url = makeUrl(appid);
        return {
          url,
          method,
          data,
        };
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled;
          customToast.success(response.data.message);

          dispatch(virtualCardApi.util.invalidateTags(["TotalCardBalance"]));
        } catch (error) {
          // Handle error if needed
        }
      },
      transformErrorResponse: (response) => {
        const message = response?.data as string;
        if (typeof response.data === "string") {
          customToast.error(message);
        }
      },
    }),
  }),
});

export const {
  useGetCardsQuery,
  useFreezeCardMutation,
  useFundCardMutation,
  useGetCardCountsQuery,
  useGetMerchantCardWalletQuery,
  useGetTotalCardBalanceQuery,
} = virtualCardApi;
