import * as tp from "../types";

export const initialState: tp.Iapplication = {
  error: null,
  allApps: {
    loading: false,
    data: [],
    totalCount: 0,
  },
  selected_transaction: {
    data: null,
    loading: false,
  },
  app_users: {
    loading: false,
    data: [],
  },
  transactions: {
    incoming_trans: {
      data: [],
      loading: false,
    },
    outgoing_trans: {
      data: [],
      loading: false,
    },
  },
  app_policies: {
    data: [],
    loading: false,
  },
  app_policies_user_category: {
    data: [],
    loading: false,
  },
  policy: {
    // query_params: "",
    policy_name: "",
    notification: {
      data: [],
      loading: false,
      totalCount: 0,
    },
    risk_policies: {
      data: [],
      loading: false,
      totalCount: 0,
    },
  },
  api_info: {
    loading: false,
    data: {
      // api_keys: {
      //   public: "",
      //   secret: "",
      // },
      webhooks: {
        settlement: null,
        transaction: null,
      },
      url: "",
    },
  },
  tabApps: [],
  plugins: {
    pricing_models: {
      data: [],
      loading: false,
    },
    selected_model: {
      data: {},
      loading: false,
    },
  },
};

export const featureParser = (features: tp.IAppFeature[]) => {
  return features.map((feature) => {
    let temp: tp.IinAppFeature | null = null;
    switch (feature.name) {
      case "BANK_TRANSFER":
      case "WALLET_TRANSFER":
      case "FUND_WALLET":
      case "GIFT_CARDS":
      case "LOANS":
      case "VIRTUAL_CARDS":
        temp = { ...feature, type: feature.name };
        break;
      case "KYC":
        temp = { ...feature, type: feature.name, status: true };
        break;
      case "INVESTMENT":
      case "SAVINGS":
        temp = { ...feature, type: "SAVINGS" };
        break;

      default:
        temp = { ...feature, type: "UTILITIES" };
        break;
    }
    return temp;
  });
};

export const updateAppHandler = (list: tp.Iapp[], payload: tp.IappRes) => {
  const newAllAppList = list.map((itm) => {
    if (itm._id === payload._id) {
      return {
        ...payload,
        app_features: featureParser(payload.app_features),
        channels:
          typeof payload.channels === "string"
            ? typeof JSON.parse(payload.channels) === "string"
              ? JSON.parse(JSON.parse(payload.channels))
              : JSON.parse(payload.channels)
            : [],
      };
    }

    return itm;
  });

  return newAllAppList;
};
export const replaceAppDataHandler = (
  list: tp.Iapp[],
  payload: { appid: string; app: tp.Iapp }
) => {
  const newAllAppList = list.map((app) => {
    if (app._id === payload.appid) {
      return payload.app;
    }
    return app;
  });

  return newAllAppList;
};
export const includeOnStateHandler = (
  list: tp.Iapp[],
  payload: { id: string; data: any }
) => {
  const newAllAppList = list.map((itm) => {
    if (itm._id === payload.id) {
      return {
        ...itm,
        include_is_active: true,
        include_auth_url: payload?.data?.auth_url,
        include_callback: payload?.data?.callback,
        include_secret: payload?.data?.secret_key,
      };
    }

    return itm;
  });

  return newAllAppList;
};
export const includeOffStateHandler = (
  list: tp.Iapp[],
  payload: { id: string }
) => {
  const newAllAppList = list.map((itm) => {
    if (itm._id === payload.id) {
      return {
        ...itm,
        include_is_active: false,
      };
    }

    return itm;
  });

  return newAllAppList;
};
