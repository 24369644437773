import { PublicKeysType, STORAGE_KEYS } from "../config/constant";

interface StorageAPI {
  get: (key: string, isSession?: boolean) => string;
  set: (key: string, value: any, isSession?: boolean) => void;
  remove: (key: string, isSession?: boolean) => void;
  clear: () => void;
  clearSession: () => void;
}

const storage: StorageAPI = {
  get: (key, isSession = false) => {
    return (isSession ? sessionStorage : localStorage).getItem(key) || "";
  },
  set: (key, value, isSession = false) => {
    (isSession ? sessionStorage : localStorage).setItem(key, value);
  },
  remove: (key, isSession = false) => {
    (isSession ? sessionStorage : localStorage).removeItem(key);
  },
  clear: () => {
    localStorage.clear();
    sessionStorage.clear();
  },
  clearSession: () => {
    sessionStorage.clear();
  },
};

export const getItem = storage.get;
export const setItem = storage.set;
export const removeItem = storage.remove;
export const clearData = storage.clear;
export const clearSessionData = storage.clearSession;

export const clearUserDetails = () => {
  removeItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
  removeItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY);
  removeItem(STORAGE_KEYS.CLIENT_LOCAL_2FA_PROMPT);
};

export const storeUserToken = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY, token);
};
export const storeRefreshToken = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY, token);
};
export const storeExpiredToken = (token: boolean) => {
  return setItem(STORAGE_KEYS.CLIENT_LOCAL_TOKEN_EXPIRED, token);
};
export const hideGolivePrompt = () => {
  return setItem(STORAGE_KEYS.CLIENT_LOCAL_SHOW_GO_LIVE, true);
};
export const ignore2FaPrompt = () => {
  return setItem(STORAGE_KEYS.CLIENT_LOCAL_2FA_PROMPT, true);
};
export const fetchExpiredToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_LOCAL_TOKEN_EXPIRED);
};
export const fetchGolivePrompt = () => {
  return getItem(STORAGE_KEYS.CLIENT_LOCAL_SHOW_GO_LIVE);
};
export const fetch2FaPrompt = () => {
  return getItem(STORAGE_KEYS.CLIENT_LOCAL_2FA_PROMPT);
};
export const removeExpiredToken = () => {
  return removeItem(STORAGE_KEYS.CLIENT_LOCAL_TOKEN_EXPIRED);
};
export const removeGolivePrompt = () => {
  return removeItem(STORAGE_KEYS.CLIENT_LOCAL_SHOW_GO_LIVE);
};

export const fetchUserToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
};
export const fetchRefreshToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY);
};

export const fetchLocalData = () => {
  return {
    access_token: fetchUserToken(),
    ignore_prompt_2fa: fetch2FaPrompt(),
    is_live_mode_ignored: fetchGolivePrompt(),
  };
};

export const storeSessionToken = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_TOKEN_SESSION_KEY, token, true);
};
export const storeSessionCompany = (data: string) => {
  return setItem(STORAGE_KEYS.CLIENT_SESSION_DATA_KEY, data, true);
};

export const fetchSessionToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_TOKEN_SESSION_KEY, true);
};

export const fetchSessionCompany = () => {
  return getItem(STORAGE_KEYS.CLIENT_SESSION_DATA_KEY, true);
};

export const fetchSessionData = () => {
  return {
    token: fetchSessionToken(),
    company_id: fetchSessionCompany(),
  };
};

export const storePublicData = (accessKey: PublicKeysType, data: any) => {
  return setItem(accessKey, data, true);
};

export const fetchPublicData = (accessKey: PublicKeysType) => {
  return getItem(accessKey, true);
};

export const removePublicData = (accessKey: PublicKeysType) => {
  return removeItem(accessKey, true);
};
