import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomInput from "@/common/components/CustomInput";
import SideModal from "@/common/components/CustomModal/SideModal";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import { iHandleBlur, iHandleChange, iHandleSubmit } from "../../../types";
import CustomButton from "@/common/components/CustomButton";
import {
  convertBankFieldsAndRules,
  getBankFieldsInitialState,
  mergeClassName,
} from "@/common/utils/helpers";
import { addBankAccount } from "../../../store/thunk";
import { ArrowBack } from "../../../../../static/images/icons";
import Language from "@/common/utils/language/en";
// import {
//   Select,
//   SelectItem,
// } from "@/common/components/CustomSelect";
import Select from "react-select";
import { Label } from "@radix-ui/react-label";

const { BankAccountDetails: PageDictionary } =
  Language.protected.walletModals.withdrawalSetting;

function BankAccountDetails() {
  const navigate = useNavigate();
  const { appid } = useParams();
  const location = useLocation();
  const [selectedBank, setSelectedBank] = useState<Record<string, string>>({});
  const account_type_id = useLocation().state?.data?.id;
  const { loading, data } = useAppSelector((state) => state.wallet.withdrawal);
  const forss = data.bank_account_types.find(
    (itm) => itm._id === account_type_id
  );

  const banks_list = data.bank_account_types
    .map((item) => ({
      value: item._id,
      label: item.transfer_type,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // const selectedBank = banks_list.find((bank) => bank.value === selectedBankId);

  const fields = forss?._id ? forss.fields : [];
  const dispatch = useAppDispatch();

  const initialstate = getBankFieldsInitialState(fields);
  const formfields = convertBankFieldsAndRules(fields);
  const [state, setState] = useState(initialstate);

  const disable_btn =
    Object.values(state).filter((itm) => itm.value === "").length !== 0 ||
    Object.values(state).filter((itm) => itm.error === true).length !== 0 ||
    !selectedBank?.value;

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setState((prev) => ({
        ...prev,
        [name]: { ...prev[name], error: false },
      }));
    } else {
      setState((prev) => ({ ...prev, [name]: { ...prev[name], error: true } }));
    }
  }, []);

  const blurHandler: iHandleBlur = useCallback((e) => {
    const { name, value } = e.target;
    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setState((prev) => ({
        ...prev,
        [name]: { ...prev[name], error: false },
      }));
    } else {
      setState((prev) => ({ ...prev, [name]: { ...prev[name], error: true } }));
    }
  }, []);

  const backHandler = () => {
    navigate(-1);
  };
  const backDropHandler = () => {
    if (loading) return;
    navigate(location.state.background.pathname);
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();

    if (!appid || loading) return;

    const temp = (Object.keys(state) as Array<keyof typeof state>).map(
      (key) => ({
        name: key,
        value: state[key].value,
      })
    );

    const req = {
      appid,
      data: { account_type_id: selectedBank?.value, fields: [...temp] },
    };

    dispatch(addBankAccount(req))
      .unwrap()
      .then(() =>
        navigate(location.state.background.pathname, { replace: true })
      );
  };

  return (
    <SideModal
      onclickBackDrop={backDropHandler}
      className="p-8 md:pt-15 md:pl-14 md:pr-28 md:pb-18"
    >
      <header className="flex items-center gap-x-8 text-3643 ">
        <button onClick={backHandler}>
          <span className="flex h-8 w-8">
            <ArrowBack />
          </span>
        </button>
        <h2>{PageDictionary.title}</h2>
      </header>
      <form className="flex flex-col gap-y-15 pt-12" onSubmit={submitHandler}>
        <h3 className="text-2017 font-medium text-gray-430">
          {PageDictionary.form.details}
        </h3>
        <div className="flex flex-col gap-y-9">
          {formfields.map((itm) => (
            <CustomInput
              key={itm._id}
              label={itm.title}
              name={itm.name}
              labelClassName="capitalize"
              value={state[itm.name].value}
              onChange={changeHandler}
              rules={itm.rules}
              haserror={state[itm.name].error}
              onBlur={blurHandler}
            />
          ))}
          <div>
            <Label
              className={mergeClassName(
                "mb-2 inline-block text-base font-medium text-base-20"
              )}
            >
              Select a Bank
            </Label>
            <Select
              options={banks_list}
              onChange={(val: any) => setSelectedBank(val)}
              isSearchable
              classNames={{
                container(props) {
                  return "rounded-xl";
                },
                control(props) {
                  return "h-14 w-full !rounded-xl border border-solid border-blue-50 bg-inputbg p-1 pr-[1.875rem] text-start text-base leading-none text-black";
                },
              }}
            />
          </div>
          <CustomButton
            disabled={disable_btn}
            isloading={loading}
            className="self-start py-3.5 px-14"
          >
            {PageDictionary.form.submit}
          </CustomButton>
        </div>
      </form>
    </SideModal>
  );
}

export default BankAccountDetails;
