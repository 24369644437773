import React, { useCallback, useState } from "react";
import cuid from "cuid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import SideModal from "@/common/components/CustomModal/SideModal";
import MediaUpload from "../../../components/MediaUpload";
import CustomInput from "@/common/components/CustomInput";
import * as Slt from "@/common/components/CustomSelect";
import CustomButton from "@/common/components/CustomButton";
import { validationRules } from "@/common/utils/helpers";
import * as tp from "../../../types";
import { createReportTransaction } from "../../../store/thunk";
import Language from "@/common/utils/language/en";

const { report_transaction: PageDictionary } =
  Language.protected.transactionModal;

type istate = {
  evidence: { name: string; id: string; url: any }[];
  title: string;
  transaction_type: string;
};
const initialstate = { evidence: [], title: "", transaction_type: "" };

export function ReportTransaction() {
  const { appid, txnid } = useParams();
  const { state } = useLocation();
  const location = state.background;
  const navigate = useNavigate();
  const [inData, setInData] = useState<istate>(initialstate);
  const [inError, setInError] = useState<Record<string, boolean>>({});
  const { loading } = useAppSelector((state) => state.app.allApps);
  const dispatch = useAppDispatch();

  const changeHandler: tp.iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));

    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setInError((prev) => ({ ...prev, [name]: false }));
    } else {
      setInError((prev) => ({ ...prev, [name]: true }));
    }
  }, []);

  const onDrop = useCallback((acceptedFiles: any, a: any, b: any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setInData((prev) => ({
          ...prev,
          evidence: [{ id: cuid(), src: file, name: file.name, url: file }],
        }));
        setInError((prev) => ({ ...prev, evidence: false }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const focusHandler = () => {
    if (inData.transaction_type === "") {
      setInError((prev) => ({ ...prev, transaction_type: true }));
    } else {
      setInError((prev) => ({ ...prev, transaction_type: false }));
    }
  };

  const removeImageHandler = (id: string) => {
    const temp = inData.evidence?.filter((img: any) => img.id !== id);
    setInData((prev) => ({
      ...prev,
      evidence: [...temp],
    }));
    setInError((prev) => ({ ...prev, evidence: true }));
  };

  const selectHandler = (val: string) => {
    setInData((prev) => ({ ...prev, transaction_type: val }));
  };

  const backDropHandler = () => {
    if (loading) {
      return;
    }
    const path = state?.pathname ? state?.pathname : location?.pathname;
    navigate(path, {
      state: { pathname: state?.pathname },
      replace: true,
    });
    // navigate(location?.pathname, {
    //   state: { pathname: state?.pathname },
    //   replace: true,
    // });
  };

  const dispatchHandler = (path: string) => {
    if (!appid || !txnid) return;
    if (Object.values(inData).includes("") || inData.evidence.length === 0)
      return;
    const data = { ...inData, evidence: inData.evidence[0].url };
    let type: tp.IreportType;

    switch (true) {
      case path.includes("incoming-settlements"):
        type = "CR";
        break;
      case path.includes("outgoing-transactions"):
        type = "DR";
        break;
      case path.includes("/utility/history"):
        type = "uhis";
        break;
      case path.includes("/utility/records"):
        type = "urec";
        break;
      case path.includes("wallet"):
        type = "wallet";
        break;

      default:
        break;
    }

    return dispatch(
      createReportTransaction({
        appid,
        txnid,
        type,
        data,
      })
    );
  };

  const submitHandler: tp.iHandleSubmit = (e) => {
    e.preventDefault();
    const path = state?.pathname ? state?.pathname : location?.pathname;
    // if (state?.pathname) {
    //   path = state?.pathname;
    // } else {
    //   path = location?.pathname;
    // }

    dispatchHandler(path)?.unwrap().finally(backDropHandler);
  };

  return (
    <SideModal
      onclickBackDrop={backDropHandler}
      className="relative flex flex-col gap-y-9 p-8 md:pl-14 md:pr-28 md:pb-24"
    >
      <header className="sticky top-0 z-10 bg-white pb-4 pt-16">
        <h3 className="text-2xl font-bold">{PageDictionary.title}</h3>
        <p className="text-sm font-normal text-gray-410">
          {PageDictionary.subtitle}
        </p>
      </header>
      <form onSubmit={submitHandler} className="z-[9] flex flex-1 flex-col ">
        <div className="z-[9] flex flex-col gap-y-4">
          <CustomInput
            label={PageDictionary.form.reportinglabel}
            name="title"
            className="h-12"
            value={inData.title}
            onChange={changeHandler}
            disabled={loading}
            rules={validationRules.string}
            haserror={inError?.title}
          />

          <Slt.Select
            label={PageDictionary.form.transactionlabel}
            name="transaction_type"
            onChange={selectHandler}
            disabled={loading}
            value={inData.transaction_type}
            onFocus={focusHandler}
            hasError={inError.transaction_type}
          >
            {PageDictionary.form.transactionOptions.map((itm) => (
              <Slt.SelectItem key={itm} value={itm}>
                <span>{itm}</span>
              </Slt.SelectItem>
            ))}
          </Slt.Select>

          <MediaUpload
            title={PageDictionary.form.uploadlabel}
            images={inData.evidence}
            onDrop={onDrop}
            onRemoveMedia={removeImageHandler}
            accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
            name="evidence"
            hasserror={inError.evidence}
            disabled={loading}
          />
        </div>
        <div className="flex flex-1 items-end ">
          <CustomButton
            disabled={
              Object.values(inData).includes("") || inData.evidence.length === 0
            }
            isloading={loading}
            className=" p-3.5"
          >
            {PageDictionary.form.submitBtn}
          </CustomButton>
        </div>
      </form>
    </SideModal>
  );
}
