import { ENDPOINTS } from "@/common/service/config/endpoint";
import { axiosPublicBaseQuery, queryHandler } from "@/common/service/request";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AcceptPaymentPayloadType,
  AcceptPaymentNoReferenceResponseType,
  PaylinkRecipientType,
  PaymentStatusResponseType,
  VerifyKycResponseType,
  VerifyKycWithEmailParams,
} from "./type";
import { APIResponseModel } from "@/common/types";

const {
  ACCEPT_PAYMENT,
  VERIFY_KYC_WITH_EMAIL,
  GET_PAYEE_ACCOUNT_INFO,
  RETRIVE_RECIPIENT,
} = ENDPOINTS.collections;

export const collectionApi = createApi({
  reducerPath: "collection",
  baseQuery: axiosPublicBaseQuery({ baseUrl: "" }),
  tagTypes: [],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    retriveRecipient: builder.query<
      APIResponseModel<PaylinkRecipientType>,
      string
    >({
      query: (recipientReference) => {
        const { url: makeUrl, method } = RETRIVE_RECIPIENT;
        const url = makeUrl(recipientReference);
        return {
          url,
          method,
        };
      },
    }),
    getPaymentStatus: builder.query<
      APIResponseModel<PaymentStatusResponseType>,
      { appid: string; payeee_reference: string }
    >({
      query: (data) => {
        const { url: makeUrl, method } = GET_PAYEE_ACCOUNT_INFO;
        const url = makeUrl(data.appid);
        return {
          url,
          method,
          headers: {
            "x-user-reference": data.payeee_reference,
          },
        };
      },
    }),
    verifyKycWithEmail: builder.query<
      APIResponseModel<VerifyKycResponseType>,
      VerifyKycWithEmailParams
    >({
      query: (params) => {
        const { url: makeUrl, method } = VERIFY_KYC_WITH_EMAIL;
        const query = queryHandler(params);
        const url = makeUrl + query;
        return {
          url,
          method,
        };
      },
    }),
    acceptPayment: builder.mutation<
      APIResponseModel<AcceptPaymentNoReferenceResponseType>,
      AcceptPaymentPayloadType
    >({
      query: (data) => {
        const { url, method } = ACCEPT_PAYMENT;
        return {
          url,
          method,
          data,
        };
      },
    }),
  }),
});

export const {
  useAcceptPaymentMutation,
  useVerifyKycWithEmailQuery,
  useRetriveRecipientQuery,
  useGetPaymentStatusQuery,
} = collectionApi;
