import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { kycFormType, resolver } from "./schema";
import {
  defaultValueHandler,
  optionsHandler,
  transferFormFeilds,
} from "./helper";
import { Form, FormItem } from "@/common/components/Form";
import { Input } from "@/common/components/Form/elements/Input";
import Select from "@/common/components/Form/elements/Select";
import CustomButton from "@/common/components/CustomButton";
import { differenceHandler } from "@/common/utils/helpers";
import { useAppDispatch } from "@/common/hooks";
import {
  createPricingPolicy,
  updatePricingPolicy,
} from "@/module/applications/store/thunk";
import OtherInfo from "../OtherInfo";
import { PricingPolicyFormProps } from "../types";

function KycForm({
  initialData,
  otherData,
  loading,
  completedHandler,
}: PricingPolicyFormProps) {
  const form = useForm<kycFormType>({
    resolver,
    defaultValues: defaultValueHandler(initialData),
  });
  const dispatch = useAppDispatch();

  const watchValues = form.watch();

  const charge_kyc = watchValues.charge_kyc;
  const filterCondition = charge_kyc === "false";

  const nonFilterCondition =
    watchValues?.markup &&
    watchValues.kyc_charge_count &&
    watchValues?.charging_cycle;

  const hideFields: Array<keyof kycFormType> = [
    "kyc_charge_count",
    "charging_cycle",
    "narration",
    "markup",
  ];

  useEffect(() => {
    if (filterCondition) {
      const condition = {
        shouldDirty: false,
        shouldTouch: false,
      };
      hideFields.forEach((key) => {
        form.setValue(key, undefined, condition);
      });
    }
  }, [charge_kyc]); //eslint-disable-line

  const submitHandler = (data: kycFormType) => {
    const temp = {
      ...data,
      charge_kyc: JSON.parse(data.charge_kyc) as boolean,
    };
    const payload = differenceHandler(temp, initialData);

    const action = initialData?._id
      ? updatePricingPolicy({
          pricingid: initialData?._id,
          ...otherData,
          ...payload,
        })
      : createPricingPolicy({
          ...otherData,
          ...payload,
        });

    dispatch(action).unwrap().finally(completedHandler);
  };

  return (
    <Form<kycFormType>
      {...form}
      form={form}
      onSubmit={submitHandler}
      className="grid grid-cols-2 gap-x-7 gap-y-8 lg:pr-7"
    >
      {transferFormFeilds
        .filter((field) => {
          if (filterCondition && hideFields.includes(field.name)) {
            return false;
          }
          return true;
        })
        .map((field) => (
          <FormItem
            key={field.name}
            name={field.name}
            label={field.label}
            required={field?.rules?.required}
            classNames={{
              label: "text-sm leading-6 text-[#090914] mb-2.5",
              item: field.name === "narration" ? "col-span-full" : "",
            }}
            render={(otherProps) => {
              return (
                <>
                  {field.type === "select" ? (
                    <Select
                      {...otherProps}
                      placeholder={field.placeHolder}
                      data={optionsHandler(field.name)}
                    />
                  ) : (
                    <Input
                      {...otherProps}
                      placeholder={field?.placeHolder}
                      suffix={field?.suffixIcon}
                      prefix={field?.prefixIcon}
                    />
                  )}
                  <OtherInfo data={field?.otherInfo} />
                </>
              );
            }}
          />
        ))}
      {filterCondition || nonFilterCondition ? (
        <div className="col-span-full col-start-1 space-y-3 [&_p]:text-[#090914]">
          <p className="text-xl font-medium leading-6">Summary</p>
          {filterCondition ? (
            <p className="text-sm font-medium [&_span]:font-bold">
              Your users will not be charged for cost of KYC
            </p>
          ) : null}
          {nonFilterCondition ? (
            <p className="text-sm font-medium [&_span]:font-bold">
              Your user will be charged{" "}
              <span>{`$${markupCalc(watchValues)}`}</span> every{" "}
              <span>{`${watchValues.charging_cycle} days`}</span> for{" "}
              <span>{`${watchValues?.kyc_charge_count} times`}</span> until the
              full <span>{`$${watchValues.markup}`}</span> is paid
            </p>
          ) : null}
        </div>
      ) : null}
      <CustomButton
        className="col-start-1 justify-self-start"
        isloading={loading}
        disabled={!form.formState.isDirty}
      >
        Submit
      </CustomButton>
    </Form>
  );
}

const markupCalc = (values: kycFormType) => {
  const markup = values?.markup || 0;
  const kyc_charge_count = values?.kyc_charge_count || 0;

  return (markup / kyc_charge_count).toFixed(2);
};

export default KycForm;
