import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { amountValidation } from "@/common/utils/helpers";

const cardFormSchema = z
  .object({
    create_card: amountValidation("Create card").transform(Number),
    card_withdrawal: amountValidation("Card Withdrawal").transform(Number),
    card_funding: amountValidation("Card Funding").transform(Number),
    card_maintenance: amountValidation("Card Maintenance").transform(Number),

    value_type: z.string({
      message: "Select fee type (flat or percentile)",
    }),
    markup: amountValidation("Amount to charge on successful txn").transform(
      Number
    ),
    insufficient_balance_penalty: amountValidation(
      "Insufficient Balance Penalty"
    ).transform(Number),
  })
  .superRefine((data, ctx) => {
    if (data.value_type === "PERCENTAGE") {
      if (data.markup > 100) {
        ctx.addIssue({
          path: ["markup"],
          message: "Markup must not exceed 100 when value type is PERCENTAGE",
          code: z.ZodIssueCode.too_big,
          maximum: 100,
          type: "number",
          inclusive: true,
        });
      }
      if (data.insufficient_balance_penalty > 100) {
        ctx.addIssue({
          path: ["insufficient_balance_penalty"],
          message:
            "Service Fee must not exceed 100 when value type is PERCENTAGE",
          code: z.ZodIssueCode.too_big,
          maximum: 100,
          type: "number",
          inclusive: true,
        });
      }
    }
  });

export const resolver = zodResolver(cardFormSchema);

export type cardFormType = z.infer<typeof cardFormSchema>;
