import React, { ReactNode } from "react";
import CustomToolTip from "../CustomToolTip";
import { mergeClassName } from "@/common/utils/helpers";

type ClassNames = "title" | "subtitle";

type Props = {
  title: string;
  subtitle: string;
  subTitleOther?: ReactNode;
  tooltip?: string;
  classNames?: Partial<Record<ClassNames, string>>;
};

function ModalTitleHeader({
  title,
  subtitle,
  tooltip,
  subTitleOther,
  classNames,
}: Props) {
  return (
    <header className="flex flex-col gap-2">
      <div>
        <h3
          className={mergeClassName(
            "text-2xl font-bold leading-6.5 text-base-40",
            classNames?.title
          )}
        >
          {title}
        </h3>
        {tooltip && <CustomToolTip tip={tooltip} />}
      </div>
      <p
        className={mergeClassName("text-sm text-gray-60", classNames?.subtitle)}
      >
        {subtitle} {subTitleOther}
      </p>
    </header>
  );
}

export default ModalTitleHeader;
