export const BASE_URL = process.env.REACT_APP_FINSWICH;
export const SOCKET_URL = process.env.REACT_APP_SOCKET;
export const PREVIEW_URL = process.env.REACT_APP_PREVIEW;
export const TERMS_URL = process.env.REACT_APP_TERMS;

export const REQUEST_TIMEOUT = 60000;
export const PAGE_SIZE = 10;

export const STORAGE_KEYS = {
  CLIENT_TOKEN_STORAGE_KEY: "finswich.token",
  CLIENT_TOKEN_REFRESH_KEY: "finswich.refresh_token",
  CLIENT_LOCAL_TOKEN_EXPIRED: "exp",
  CLIENT_TOKEN_SESSION_KEY: "finswich.session.token",
  CLIENT_SESSION_DATA_KEY: "finswich.external.id",
  CLIENT_LOCAL_2FA_PROMPT: "finswich.session.2fa",
  CLIENT_LOCAL_SHOW_GO_LIVE: "finswich.session.golive",

  CLIENT_PUBLIC_KEY: "finswich.public.key",
  CLIENT_PULIC_REFERENCE: "finswich.public.reference",
  CLIENT_PULIC_ORIGIN: "finswich.public.origin",
};

export type PublicKeysType = "key" | "reference" | "origin";
export const storeHelper: Partial<Record<PublicKeysType, string>> = {
  key: STORAGE_KEYS.CLIENT_PUBLIC_KEY,
  reference: STORAGE_KEYS.CLIENT_PUBLIC_KEY,
  origin: STORAGE_KEYS.CLIENT_PUBLIC_KEY,
};
