import React from "react";
import { useNavigate } from "react-router-dom";
import SuccessSideModal from "@/common/components/CustomModal/SuccessSideModal";
import routes from "@/common/routes";

export function CreateApplicationSuccess() {
  const navigate = useNavigate();
  const backToApplication = () =>
    navigate(routes.protected.applications.index, { replace: true });
  return (
    <SuccessSideModal
      action={backToApplication}
      title="Payment Successful"
      subtitle="Your payment has been received"
      cta="Close"
    />
  );
}
