const Language = {
  networkErrorMessage: {
    genericErrorMessage: "Check your Connection and Refresh Page",
    serverError: "Wait a moment to retry",
  },
  components: {
    input_feild: {
      invalid_value: "* This is not a valid value for this input field",
    },
  },
  nonprotected: {
    welcome: {
      heading: "Wallet Inter-operability",
      subHeading: {
        main: "We help wallet provider to convert their users’ phone numbers to an account that can send or receive money from over 1,000 wallet platforms on",
        sub: "FinSwich",
      },
      register: "Sign up",
      login: "Login",
    },
    LoginPage: {
      pageTitle: "",
      heading: "Welcome",
      subHeading: "Input your details to login",
      emailLabel: "Email address",
      passwordLabel: "Password",
      rememberMeLabel: "Remember me",
      forgotpaswordText: "Forgot Password? Click Here",
      loginActionButton: "Login",
      swithPage: {
        text: "Don’t have an account? ",
        registerActionLink: "Signup here",
      },
    },
    registerPage: {
      pageTitle: "",
      heading: "Welcome",
      subHeading: "Input your details to sign up",
      inviteSubHeading: "Input your details to complete your invitation",
      companyNameLabel: "Company name",
      emailLabel: "Email address",
      passwordLabel: "Password",
      countryLabel: "Country",
      registerActionButton: "Sign up",
      swithPage: {
        text: "Have an account? ",
        loginActionLink: "Login here",
      },
    },
    forgotPasswordPage: {
      pageTitle: "",
      heading: "Forget password",
      subHeading: {
        forgotpassword: "Input your details to reset your account password",
        enterotp: "Input the one time password sent to your email",
        newpassword: "Input the one time password sent to your email",
      },
      emailLabel: "Enter Email address",
      otplabel: "Enter Otp pin",
      resendCode: "Resend it",
      newPasswordlabel: "New Password",
      confirmPasswordlabel: "Confirm Password",
      submitAction: "Submit",
    },
    enterOtpPage: {
      pageTitle: "",
      heading: "Confirm 2FA pin",
      subHeading:
        "Enter the verification code sent to your email to complete account creation",
      otpLabel: "Input code",
      submitAction: "Submit",
      resendCode: "Didn’t get code? Resend",
    },
    resetSuccessfullPage: {
      pageTitle: "",
      heading: "Password Reset Successful",
      subHeading:
        "Your password has been successfully reset, you proceed to log in",
      action: "Go to Login",
    },
    errorPage: {
      pageTitle: "Error Page",
      heading: "Ooops...",
      subheading: "Page Not Found",
      login: "To Login",
      dashboard: "To Dashboard",
      onboarding: "To Onboarding",
    },
  },
  protected: {
    Layout: {
      auth_layout: {
        page_title: "Welcome",
      },
      sidenav: {
        nav: {
          welcome: "Welcome",
          dashboard: "Dashboard",
          applications: "Applications",
          apps: "All Apps",
          compliance: "Compliance",
          settings: "Settings",
          onboarding: "Onboarding",
          preview: "Preview App",
          user: "Admin",
          white_label: {
            index: "Whitelabel",
            plans: "Plans",
            active_subscriptions: "Active Sub.",
            merchant: "Merchants.",
          },
        },
        in_app: {
          wallet: {
            index: "Wallet",
          },
          transactions: {
            index: "Transactions",
            incoming: "PayIn",
            outgoing: "PayOut",
          },
          vas_services: {
            index: "VAS Services",
            wallet: "Wallet",
            utilities: "Utilities",
            records: "Records",
          },
          virtual_cards: {
            index: "Virtual Cards",
            wallet: "Wallet",
            cards: "Cards",
            transactions: "Transactions",
            pricing: "Pricing",
          },
          neo_banking: {
            index: "Neo-Banking",
            wallet: "Wallet",
            pricing: "Pricing",
            domain: "Custom Domain",
            branding: "App branding",
            template: "App Templates",
          },
          plugin_services: {
            index: "In-App Widget",
            services: "Services",
            branding: "Branding",
          },
          users: {
            index: "Users",
          },
          risk_control: {
            index: "Risk Control",
            policy: "Policy",
            categories: "Categories",
            risks: "Risks",
          },
          settings: {
            index: "Developer",
            api_keys: "Api Keys",
            prod_webhooks: "Live Config",
            test_webhooks: "Test Config",
          },
        },
        logout: "Logout",
      },
      application_layout: {
        page_title: (app_name?: string) => `Applications ${app_name}`,
        nav: {
          wallet: "Wallet",
          outgoing_tx: "Outgoing Tx",
          incoming_tx: "Incoming Settlement",
          utility: "Utility",
          users: "Users",
          policy: "Policy",
          api_setting: "API Setting",
          preferences: "Checkout",
          analytics: "Analytics",
          include: "Include",
        },
      },
      wallet_setting_layout: {
        header: {
          title: "Beneficiaries",
          add_details: "Add Details",
        },
        nav: {
          crypto: "Crypto Beneficiaries",
          bank: "Bank Account Beneficiaries",
        },
      },
      utility_layout: {
        banner: {
          balance: "Utility Balance",
          threshold_text:
            "**This feature will alert you when you balance is running low",
          threshold: {
            update: "View Threshold alert",
            set: "Set Threshold alert",
          },
          error: {
            no_bank_withdrawal_details:
              "Please go to Withdrawal settings to add Bank Details",
            no_crypto_withdrawal_details:
              "Please go to Withdrawal settings to add Crypto Withdraw Wallet Address",
          },
          withdraw_btn: "Withdraw",
          fund_btn: "Fund",
          setting_btn: "Withdrawal Settings",
        },
        header: {
          title: "Utility Records",
          headline_text:
            "Access over 10,000 Value Added Services (VAS) in over 54 Countries in the world. Give your users unlimited options to do more with your app beyond your location.",
          filter_label: "Filter by:",
          filter: {
            country: "Country",
            // timestamp: "TimeStamp",
            // utilityType: "Utility Type",
            // provider: "Provider",
            category: "Category",
            sub_category: "Sub Category",
          },
        },
        nav: {
          records: "Wallet Records",
          history: "Utility History",
        },
      },
      wallet_withdrawal: {
        bank_transfer: "Bank Transfer",
        crypto: "Crypto",
        no_crypto_withdrawal_details:
          "Please go to Withdrawal settings to add Crypto Withdraw Wallet Address",
      },
      policy_layout: {
        header: {
          title: "Risk Records",
          sub_title: "Policy",
          add_config: "Add Configuration",
          go_back: "Back",
        },
        nav: {
          risk_record: "All Risk Records",
          noti_config: "Notification Configuration",
        },
      },
      compliance_layout: {
        page_title: "Compliance",
        add_shareholder: "Add Shareholder",
        nav: {
          bussiness_document: "Business Document",
          bussiness_shareholders: "Shareholders",
          bussiness_profile: "Business Profile",
        },
      },
      onboarding_layout: {
        page_title: "Onboarding",
        title: "Compliance",
        copy: "Kindly Agree to our terms, provide information below to perform your KYC before you can access the full capabilities of FinSwich.",
        add_shareholder: "Add Shareholder",
        nav: {
          terms: "Terms",
          bussiness_document: "Document",
          bussiness_shareholders: "Shareholders",
          bussiness_profile: "Business Profile",
        },
      },
      setting_layout: {
        page_title: "Settings",
        add_shareholder: "Add Shareholder",
        nav: {
          security: "Security",
          business_profile: "Business Profile",
          tx_country: "Tx. Country",
          activity_log: "Activity Log",
        },
      },
      include_layout: {
        nav: {
          config: "Configuration",
          wallet: "Wallet",
          pricing: "Pricing",
          url: "URL",
        },
      },
    },
    DashboardPage: {
      pageTitle: "Dashboard",
      cards: {
        transactions: "Total Tx",
        users: "Total users",
        volume: "TX Volume",
        settlement: "Total settlement",
      },
    },
    welcomePage: {
      pageTitle: "Welcome",
      header: {
        title: "Welcome, Explore the Power of Finswich",
        sub: "Finswich by Fuspay Technology Inc. ",
      },
      banner: {
        title: "Launch Financial Product faster.",
        list: [
          "Crypto to Fiat  Swapping",
          "Cross-Border Transfer (18 countries)",
          "Gift & Virtual Cards",
          "VAS in  54 countries",
        ],
      },
      body: {
        title: "How to Leverage Finswich",
        list: [
          {
            title: "White-Label",
            content:
              "Launch a Neo-banking app in just 30 minutes by leveraging our white-label solutions, supporting payments in 18 countries and VAS in 54 countries. This allows you to focus on customer acquisition and growth.",
            action: "Try now",
          },
          {
            title: "In-App Plugin",
            content:
              "If you have an app and want to offer a wider range of financial services to your customers, enhance your offerings by embedding our plugin. Access VAS, wallet funding, virtual cards, gift cards, and more.",
            action: "Try now",
          },
        ],
        demo: {
          title: "Overview on Finswich",
          content: "Watch a quick overview on how to leverage Finswich.",
        },
      },
      footer: {
        title: "Compliance Required!",
        content: "Sign an MSA, submit company document to get started.",
        action: "Proceed to Compliance",
      },
    },
    ApplicationsPage: {
      page_title: "Applications",
      header: {
        title: "List of Apps",
        btn_title: "Create App",
      },
      table: {
        app_name: "Application Name",
        app_id: "App ID",
        config_key: "Configuration Keys",
        created: "Date Created",
      },
    },
    ApplicationPages: {
      preferencePage: {
        section_feature: {
          title: "Checkout Features",
          subtitle: {
            main: "Select the service you will like your customers to see on your",
            sub: `“Wallet Checkout”`,
          },
          data: [
            {
              title: "Global Transfer",
              sub: "Enabling bank transfers at checkout allows your users to make transfers to different bank accounts across various countries.",
              name: "BANK_TRANSFER", //please do not change the value of name
              featureIncomplete: false,
              requiredFeature: false,
              pricing_type: "GLOBAL_TRANSFERS",
            },
            {
              title: "Finswich Transfer",
              sub: "Enabling users to make  transfers to other users across different financial institution on the  Finswich network.",
              name: "WALLET_TRANSFER", //please do not change the value of name
              featureIncomplete: false,
              requiredFeature: false,
              pricing_type: "GLOBAL_TRANSFERS",
            },
            {
              title: "Fund Wallet",
              sub: "Turning on the fund wallet feature allows your users to directly fund their wallets with your service.",
              name: "FUND_WALLET", //please do not change the value of name
              featureIncomplete: false,
              requiredFeature: false,
              pricing_type: "GLOBAL_TRANSFERS",
            },
            {
              title: "Gift Cards",
              sub: "Give your customers ability to make payment in more than 1000 website through vouchers and gift cards.",
              name: "GIFT_CARDS", //please do not change the value of name
              featureIncomplete: false,
              requiredFeature: false,
              pricing_type: "CARD",
            },
            {
              title: "Utility Services",
              sub: "Let your users have access to over 10,000 VAS options in 54 countries in the world. E.g data, mobile tops, insurance, e-bills etc.",
              name: "UTILITIES", //please do not change the value of name
              featureIncomplete: false,
              requiredFeature: false,
              pricing_type: "GLOBAL_TRANSFERS",
            },
            {
              title: "Virtual Cards",
              sub: "Enabling savings at checkout gives your app and users the ability to save and fund their accounts.",
              name: "VIRTUAL_CARDS", //please do not change the value of name
              featureIncomplete: false,
              requiredFeature: false,
              pricing_type: "CARD",
            },
            {
              title: "In-Built KYC",
              sub: "An in-built KYC that allow you to be compliant with regulators across different market.",
              name: "KYC", //please do not change the value of name
              featureIncomplete: false,
              requiredFeature: true,
              pricing_type: "KYC",
            },
            {
              title: "Invest",
              sub: "Give your customers the ability to invest in US Stock market",
              name: "INVESTMENT", //please do not change the value of name
              featureIncomplete: true,
              requiredFeature: false,
              pricing_type: "GLOBAL_TRANSFERS",
            },
            {
              title: "Virtual Phone  No.",
              sub: "Give your customers the ability to have virtual phone number across different countries.",
              name: "VIRTUAL_PHONE_NO", //please do not change the value of name
              featureIncomplete: true,
              requiredFeature: false,
              pricing_type: "KYC",
            },
            {
              title: "Virtual Address",
              sub: "We give your customers the ability to have a virtual address to do their business and receive mail.",
              name: "VIRTUAL_ADDRESS", //please do not change the value of name
              featureIncomplete: true,
              requiredFeature: false,
              pricing_type: "KYC",
            },
          ],
        },
        section_apperance: {
          title: "Checkout Appearance",
          logo: {
            title: "Checkout Logo",
            subtitle:
              "Change the logo that your customer will see on your checkout.",
            image_description: "checkout_logo preview",
          },
          color: {
            title: "Checkout Background Color",
            subtitle:
              "Change the logo that your customer will see on your checkout.",
            background_color: "Background Color",
            text_color: "Text Color",
            header_color: "Header Color",
          },
          white_label: {
            title: "White Label",
            subtitle: {
              remove: "Apply the “Powered by FinSwich” logo on your checkout .",
              replace:
                "Replace the “Powered by FinSwich” logo on your checkout .",
            },
            image_description: "white label",
            apply_btn: "Apply for White Label",
          },
        },
        section_preview: {
          white_label_description: "white label logo",
          logo_descption: "application logo",
          customer_name: "John Doe",
          wallet_balance: "Wallet Balance",
          fin_number: "Finswich Number",
        },
        submit_btn: "Update",
      },
      Transaction_information: {
        back_btn: "Back",
        title: "More Information",
        report_btn: "Report Transaction",
        table_titles: {
          general: "General details",
          customer: "Customer details",
          beneficiary: "Beneficiary details",
        },
        tables: {
          general_table: {
            amount: "Amount",
            type: "Type",
            ref: "Ref",
            date: "Date",
            exchange_rate: "Ex Rate",
            destination_country: "Destination country",
            status: "Tx Status",
            currency: "Currency",
            balance: "Finswich balance",
            commission: "Commission/Fee",
            description: "Description",
          },
          customer_table: {
            name: "Customer name",
            email: "Customer email",
          },
          beneficiary_table: {
            name: "Benefiaciary name",
            number: "Benefiaciary number",
            app: "Benefiaciary app",
            country: "Destination country",
          },
        },
      },
      Include_page: {
        pageTitle: "Include",
        hero: {
          active_label: "Active",
          inactive_message:
            "Once you Turn On Include, You will no longer have access to the Funds of your users. Only Commission of your transactions will be settled to your Wallet",
          copy: "Include app gives you the flexibility to quickly start up your own Finance app project within your community without the need for technical team.",
          external_links: {
            video_demo_title: "To Learn More Watch Demo",
          },
        },
        preference_section: {
          title: "Include Preferences",
          community: {
            title: "Start Your own Fintech in your community",
            copy: "We offer you a complete mobile for fintech service with varieties of services you can offer to customers in your community. With our White label solution you can have your own fintech in less than 24hours and start onboarding customers immediately",
          },
          configure: {
            title: "Configure Preferences",
            form: {
              logo: {
                logo_label: "Logo to show on the App",
                preview: "Logo Preview",
                app_name_color_label: "App name text Color",
                app_name_alias: "App Alias (Name visible on the app)",
              },
              card: {
                card_image_label: "Card  Image",
                preview: "Image Preview",
                text_color_label: "Card text Color",
                bg_color_label: "Card BG Color",
              },

              colors: {
                text_color_label: "Text Color",
                primary_color_label: "Primary Color",
                secondary_color: "Secondary Color",
                background_color_label: "Background Color",
                button_color_label: "Button  Color",
              },
              checkout: {
                edit_title: "Edit Wallet Checkout Preferences",
                edit_link: {
                  copy: "To change the wallet checkout preferences, ",
                  action: "Click here",
                },
              },
              submit_form_label: "Save  Settings",
            },
          },
        },
        preview_section: {
          app_name: "Qpay",
          app_user_text: "Good Morning Charles,",
          banner: {
            wallet_balance: "Wallet Balance",
            wallet_value: 100340,
          },
          transaction_history: {
            title: "Transactions Records",
            seeMore: "See All",
            deposit: {
              title: "Savings deposit",
              desc: "Payment ID: 12484jejsdjsismw9",
              time: "Date: mon 23 jan 2023   20:45:12",
              amount: "+ $0.00",
            },
            withdrawal: {
              title: "Withdrawal Made",
              desc: "Payment ID: 12484jejsdjsismw9",
              time: "Date: mon 23 jan 2023   20:45:12",
              amount: "+ $0.00",
            },
          },
        },
      },
    },
    IncludeModals: {
      turnOnInclude: {
        header: {
          title: "Permission is required to perform this action",
          sub: {
            text: "This action will activate the Include feature. Entering your Public key is required to ensure you have the right permission.",
            cta: "Do you want to continue",
          },
        },
        form: {
          uploadKeyLabel: "Upload Generated Keys",
          secretKeyLabel: "Enter Secret Key",
          publictKeyLabel: "Enter Public Key",
          cancelButton: "Cancel",
          submitButton: "Submit",
        },
      },
      turnOffInclude: {
        header: {
          title: "Permission is required to perform this action",
          sub: {
            text: "This action will turn off and deactivte the Include feature and will also generate new API KEYS.",
            cta: "Do you want to continue",
          },
        },
        form: {
          passwordLabel: "Enter Password:",
          cancelButton: "Cancel",
          submitButton: "Submit",
        },
      },
    },
    CreateApplicationModal: {
      CreateApplicationForm: {
        title: {
          create: "Create New Application",
          update: "Update Application",
        },
        form: {
          app_name_label: "App Name",
          app_owner_label: "App owner/wallet provider",
          app_logo: "App logo",
          upload_support_text: "max image size 5 mb",
          app_owner_logo: "Wallet Provider logo",
          app_industry: "Business type",
          app_industry_list: [
            "Fishing",
            "Horticulture",
            "Tobacco",
            "Wood",
            "Aerospace",
            "Automotive",
            "Pharmaceutical",
            "Construction",
            "Defense",
            "Electric power",
            "Electronics",
            "Energy",
            "Food",
            "Industrial robot",
            "Low technology",
            "Meat",
            "Mining",
            "Petroleum",
            "Pulp and paper",
            "Steel",
            "Shipbuilding",
            "Telecommunications",
            "Textile",
            "Water",
            "Advertising",
            "Fashion",
            "Floral",
            "Cultural",
            "Culture",
            "Education",
            "Film",
            "Gambling",
            "Music",
            "Sex",
            "Video game",
            "Insurance",
            "Healthcare",
            "Hospitality",
            "Information",
            "Leisure",
            "Broadcasting",
            "Internet",
            "News media",
            "Publishing",
            "Entertainment",
            "Professional services",
            "Real estate",
            "Retail",
            "Software",
            "Sport",
            "Transport",
            "Banking",
            "Finance",
            "Blockchain",
            "Crypto",
            "Fintech",
          ].sort((a, b) => a.localeCompare(b)),
          app_description: "App description",
          app_channels: {
            title: "App channels",
            channels: {
              ios: "Ios",
              web: "Web",
              andriod: "Andriod",
            },
          },
          app_url: "Provide your app url or app id on playstore or appstore",
          app_user_base: "Estimated User-base",
          confirm_kyc:
            "I confirm that i do KYC on all my users and I also confirm that my platform is not for money laundering.",
          submit_form: {
            submit: "Submit",
            update: "Update",
          },
        },
        error: {
          fill_empty_fields: "Cannot Submit Please fill highlighted fields",
        },
      },
      ApplicationCheckoutForm: {
        title: "Checkout",
        sub_title: "Enter your payment details to proceed",
      },
    },
    ApplicationTransactionPage: {
      table: {
        amount: "Amount",
        local_amount: "Local Amount",
        app_id: "App ID",
        reference: "Reference",
        description: "Description",
        timestamp: "Timestamp",
        balance: "Balance",
        fee: "Fee",
        status: "Status",
      },
    },
    ApplicationUsersPage: {
      table: {
        full_name: "Full Name",
        // phone: "Phone",
        email: "Email",
        category: "User category",
        country: "Country",
        // acc_no: "Account No.",
        //   kyc: "KYC",
        total_tx: "Total tx",
        time: "Timestamp",
        action: "Action",
      },
      table_component: {
        block_user: "Block User",
      },
    },
    ApplicationUserModal: {
      block_user: {
        title: "Are you sure you want to take this action?",
        subtitle: (active_status: boolean) => `You are about to ${
          active_status ? "" : "un"
        }block this user on this platform, confirm if you want
        to continue with this action?`,
        actions: {
          cancel: "Cancel",
          continue: "Yes",
        },
      },
    },
    transactionModal: {
      report_transaction: {
        title: "Report Transaction",
        subtitle:
          "You can report a transaction that failed with this form below",
        form: {
          reportinglabel: "What are you reporting *",
          transactionlabel: "Select kind of transaction*",
          transactionOptions: [
            "Fraudulent Transaction",
            "Suspicious Transaction",
            "Double Credit Transaction",
            "Failed Transaction",
          ],
          uploadlabel: "Upload Evidence",
          submitBtn: "Submit",
        },
      },
    },
    ApplicationPolicyPage: {
      transaction_policy: {
        header: {
          title: "Transaction Policies",
          tooltip:
            "A transaction policy is a set of rule-value pair . E.g (Maximum threshold amount (per day) = #3000000) Transaction policies allow you to set up rules to govern your user's behavior on each application. You can have policies governing all your user's outgoing or incoming transactions e.g. the amount of money a user can send outside of your platform in a day. User transactions on your platform can be of two types: transactions from your own users going out to other platforms (Outgoing transactions) and from other apps on Finswich to your own users (Incoming transactions).",
          create_policy: "Create Policy",
        },
        table: {
          policy_name: "Policy Name",
          rule: "Rule",
          reference: "Reference",
          risk_management: {
            title: "Risk management",
            tooltip:
              "This allows you to see and manage all the transactions that have broken a particular policy you have set.",
          },
          time: "Timestamp",
          action: "Action",
        },
        table_component: {
          block_user: "Block User",
          view: "View",
          add: "+ Add",
        },
      },
      user_category: {
        header: {
          title: "User Category",
          tooltip:
            "A user category is a subset of your user base. Businesses implore multiple internal criteria in dividing their customer base into tiers. The Finswich user category section helps you map a user category to a set of policies. E.g. You could map your platinum users or Tier 1 users to a specific set of policies that helps them maximize your platform.",
          create_category: "Create Category",
        },
        table: {
          user_cat: "User Category",
          incoming_policy: "Incoming policy",
          outgoing_policy: "Outgoing policy",
          cat_id: "Category ID",
          date: "Date",
          action: "Action",
        },
        table_compnent: {
          manage: "Manage",
        },
      },
      risk_record: {
        table: {
          trans_ref: "Transaction Ref",
          email: "Email",
          amount: "Amount",
          rule: "Flagged  Rule",
          date: "Date",
          action: "Action",
        },
        table_compnent: {},
      },
      notification_config: {
        table: {
          rule: "Rule",
          notification_method: "Notification Method",
          schedule: "Schedule",
          notify_to: "Notify to",
          manage: "Manage",
        },
        table_compnent: {},
      },
    },
    ApplicationPolicyModal: {
      create_policy_form: {
        header: {
          title: {
            create: "Create Policy",
            update: "Update Policy",
          },
          subtitle: {
            create: "Create transaction policy",
            update: "Update transaction policy",
          },
        },
        form: {
          policy_type_fieldset: "Type of policy",
          incoming_txn: {
            label: "Incoming Txn policy",
            tooltip:
              "Incoming policies are used to control how users from other platforms interact with your own users. Eg You could set the maximum amount a user on your platform can receive (Maximum threshold amount (per day) to prevent money laundry.",
          },
          outgoing_txn: {
            label: "Outgoing Txn policy",
            tooltip:
              "Outgoing policies are used to control how your users interact with other users of apps registered on Finswich Eg You could set the maximum amount your user can send to another app (Maximum threshold amount (per time)",
          },
          policy_name: "Policy name",
          policy_desc: "Description",
          total_txn_day: {
            label: "Total tx frequency (per day)",
            tooltip:
              "This is used to control the number of transactions a user perform in a day",
          },
          max_threshold_day: {
            label: "Maximum threshold amount (per day)",
            tooltip:
              "This is the maximum amount a user can transact in a single day",
          },
          max_threshold_time: {
            label: "Maximum threshold amount (per time)",
            tooltip:
              "This is the maximum amount a user can send or receive in a single transaction",
          },
          time_btw_txn: {
            label: "Time in between two tx",
            placeholder: "Please Select",
            tooltip:
              "What is the minimum amount of time between two transactions initiated by a user",
            options: [
              15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210,
              225, 240, 255, 270, 285, 300,
            ],
          },
          quarantine_txn_amount: {
            label: "Quarantine Tx amount threshold",
            tooltip:
              'Anything above the "Tx threshold amount" should be quarantined',
          },
          quarantine_txn_freq: {
            label: "Quarantine Tx frequency threshold",
            tooltip:
              'Anything above the "Tx Frequency threshold" should be quarantined',
          },
        },
        form_submit: {
          submit: "Submit",
          update: "Update",
        },
      },
      policy_rules: {
        title: "Policy Rules",
        type: {
          incoming: "Incoming Rules",
          outgoing: "Outgoing Rules",
        },
        rules: {
          max_daily_txn_freq: "Incoming Total tx frequency (per day)",
          max_daily_txn_amount: "Incoming Maximum threshold amount (per day)",
          max_transaction_amount:
            "Incoming Maximum threshold amount (per time)",
          allowed_duration_btw_transaction: "Incoming Time in between two tx",
          quarantine_transaction_amount:
            "Incoming Quarantine Tx amount threshold",
          quarantine_transaction_freq:
            "Incoming Quarantine Tx frequency threshold",
        },
        edit_button: "Edit",
      },
      create_user_category: {
        header: {
          title: {
            create: "Create category",
            update: "Update category",
          },
          subtitle: {
            create: "Create category for customers",
            update: "Update category for customers",
          },
        },
        form: {
          category_name: "Category Name",
          incoming_policy_label: "No incoming policy",
          outgoing_policy_label: "No Outgoing policy",
          incoming_policy_placeholder: "Select Incoming Policy",
          outgoing_policy_placeholder: "Select Outgoing Policy",
        },
        form_button: {
          submit: "Save",
          update: "Update",
        },
      },
      block_policy: {
        title: "Are you sure you want to take this action?",
        subtitle: (active_status: boolean) => `You are about to ${
          active_status ? "" : "un"
        }block this policy on this platform, confirm if you want
        to continue with this action?`,
        actions: {
          cancel: "Cancel",
          continue: "Yes",
        },
      },
    },
    utilityPages: {
      UtilityHistoryPage: {
        table: {
          amount: "Amount",
          tx_type: "Tx Type",
          provider: "Provider",
          tx_ref: "Reference",
          remark: "Remark",
          timestamp: "Timestamp",
          status: "Status",
          action: "Action",
        },
        table_component: {
          tx_type: {
            data: "Data",
            airtime: "Airtime",
          },
          txn_status: {
            pending: "Pending",
            success: "Success",
            cancelled: "Cancelled",
            rejected: "Rejected",
          },
        },
      },
      WalletRecordsPage: {
        table: {
          amount: "Amount",
          local_amount: "Local Amount",
          tx_type: "Tx Type",
          tx_ref: "Reference",
          description: "Description",
          timestamp: "Timestamp",
          status: "Status",
        },
        table_component: {
          tx_type: {
            credit: "Credit",
            debit: "Debit",
          },
          txn_status: {
            pending: "Pending",
            success: "Success",
            cancelled: "Cancelled",
            rejected: "Rejected",
          },
        },
      },
    },
    includePages: {
      WalletPage: {
        table: {
          amount: "Amount",
          rate: "Exchange Rate",
          tx_ref: "Reference",
          sender_email: "Sender’s Email ",
          timestamp: "Timestamp",
          balance: "Balance",
          fee: "Fee",
        },
      },
    },
    walletPages: {
      walletPage: {
        banner: {
          balance: "Balance",
          threshold_text:
            "**This feature will alert you when you balance is running low",
          threshold: {
            update: "Update Low Balance Notification",
            set: "Set Low Balance Notification",
          },
          // error: {
          //   no_bank_withdrawal_details:
          //     "Please go to Withdrawal settings to add Bank Details",
          //   no_crypto_withdrawal_details:
          //     "Please go to Withdrawal settings to add Crypto Withdraw Wallet Address",
          // },
          withdraw_btn: "Withdraw",
          fund_btn: "Fund",
          setting_btn: "Beneficiaries",
        },
        header: {
          title: "Wallet Records",
          funding_label: "Funding",
          withdrawal_label: "Withdrawal",
        },
        table: {
          amount: "Amount",
          local_amount: "Local Amount",
          tx_type: "Tx Type",
          tx_ref: "Reference",
          description: "Description",
          timestamp: "Timestamp",
          status: "Status",
        },
        table_component: {
          tx_type: {
            credit: "Credit",
            debit: "Debit",
          },
          txn_status: {
            pending: "Pending",
            success: "Success",
            cancelled: "Cancelled",
            rejected: "Rejected",
            failed: "Failed",
          },
        },
      },
      cryptoWithdrawalPage: {
        table: {
          address: "Address",
          network: "Network",
          token_name: "Token Name",
        },
        table_component: {
          deactivate: "Deactivate",
          activate: "Activate",
        },
      },
    },
    walletModals: {
      funding: {
        AwaitingConfirmation: {
          title: "Payment Confirmation",
          sub_title:
            "Please wait while your payment is been conformed by the exchanger",
        },
        FundBankForm: {
          header: {
            title: "Funding your wallet",
            subtitle: "Choose your source to credit your wallet",
          },
          form: {
            amount_label: "Amount to Pay",
            upload_label: "Upload Payment Evidence",
            upload_supportFormatText:
              "Supported formats: JPEG & PNG are allowed, max image size 5 mb",
            submit_btn: "I have Paid",
          },
        },
        FundCryptoForm: {
          header: {
            title: "Funding your wallet",
            subtitle: "Choose your source to credit your wallet",
          },
          form: {
            coin_label: "Coin to fund",
            address_label: "Wallet Address",
            submit_btn: "Close",
          },
        },
        FundWalletForm: {
          header: {
            title: "Funding your wallet",
            subtitle: "Choose your source to credit your wallet",
            subtitle_utility:
              "Credit your utility wallet using your general app wallet",
          },
          form: {
            amount_label: "Amount to Fund",
            amount_dollar: "Amount in dollar:",
            warn_text:
              "Once you submit, an account number will be generated for you to pay into. pay and click I have Paid",
            warn_text_utility:
              "Once you submit, your general app wallet will be debited for this amount",
            submit_btn: "Continue",
          },
        },
        FundWalletSuccess: {
          title: "Payment Successful",
          subtitle: "Your request has been successfully submitted.",
          submit_btn: "Done",
        },
        PaymentProcessing: {
          awaiting_confirmation: "Funding Awaiting confirmation",
          fetching_account: "Fetching Payment information",
          process_initiated: "Funding initiated",
          processing: "We are processing ...",
          routing:
            "We are routing this funding requeste for your funding to be processed",
        },
      },
      withdrawal: {
        AuthorizeWithdrawal: {
          title: "Authorize Withdrawal",
          subtitle: "Confirm withdrawal Information",
          form: {
            password_label: "Enter password to confirm transaction",
            submit_btn: "Submit",
          },
        },
        WithdrawlForm: {
          title: "Withdrawal",
          subtitle: "Choose your source to withdraw",
          utility_subtitle: "Withdraw to your app Wallet",
          error: {
            no_account: "Please go to Withdrawal settings to add Bank Details",
            no_active:
              "Please go to Withdrawal settings to activate a Bank Account Detail",
          },
          form: {
            amount_label: "Amount to withdraw",
            amount_local_label: "Amount in local Fiat : ",
            destination: "Choose withdrawal destination",
            submit_btn: "Continue",
          },
        },
        WithdrawalThreshold: {
          title: {
            update: "View Low Balance Notification",
            set: "Set Low Balance Notification",
          },
          subtitle: "Setup alert for your balance",
          form: {
            alert: {
              label: "Alert Balance",
              sub: "Input the balance limit you want to get Alert when your balnace get to that amount",
            },
            email: {
              label: "Email",
              sub: "Input the email which you want to get your alert remider when your balance gets to the amount set",
            },
            update_btn: "Update",
            submit_btn: "Submit",
          },
        },
        WithdrawalSuccess: {
          title: "Your Transaction Is On Its Way",
          subtitle: "Your withdrawal request has been successfully submitted.",
          submit_btn: "Complete",
        },
      },
      withdrawalSetting: {
        BankAccountDetails: {
          title: "Withdrawing Settings",
          form: {
            details: "Bank account withdrawal details",
            submit: "Submit",
          },
        },
        BankAccountType: {
          title: "Withdrawing Settings",
          form: {
            details: "Bank account withdrawal details",
            submit: "Submit",
          },
        },
        CryptoSetting: {
          title: "Withdrawing Settings",
          form: {
            details: "Bank account withdrawal details",
            asset: "Asset",
            address: "Crypto address",
            network: {
              title: "Network",
              name: "TRC - 20",
              note: {
                title: "Note: ",
                sub: "Make sure the address you are funding from and withdrawing to carries same network as TRC - 20, you might lose asset if you are not same network.",
              },
            },
            submit: "Submit",
          },
        },
        DeactivateAccount: {
          title: "Are you sure you want to take this action?",
          subtitle_activate:
            "You are about to Activate this Account, confirm if you want to continue with this action?",
          subtitle_deactivate:
            "You are about to Deactivate this Account, confirm if you want to continue with this action?",
          cancel: "Cancel",
          accept: "Yes",
        },
        SettingsAddedSuccess: {
          title: "Setting Added Successfully",
          subtitle: "",
          close: "Close",
        },
      },
    },
    compalinacePages: {
      banner: {
        title: "Compliance Required!",
        copy: "Sign an MSA, submit company document to get started.",
        body: "If you're experiencing any technical issues while trying to submit compliance documents, contact our live chat now.",

        start: "Start Compliance",
        chat: "Live Chat",
        issues: "Experiencing Technical Issue?",
      },

      business_document: {
        table: {
          document_name: "Document Name",
          action: "Action",
          status: "Status",
          created_at: "Date Created",
        },
        table_component: {
          document_name: {
            CERTIFICATE_OF_INCORPORATION:
              "Business Certificate of Incorporation",
            BUSINESS_OPERATING_LICENSE: "Business operating License",
            SOURCE_OF_WEALTH: "Source of Wealth",
            ANTI_MONEY_LAUNDERING: "Anti money laundering",
            PROOF_OF_ADDRESS: "Proof of business Address",
            BANK_STATEMENT: "3 month Bank Statement",
          },
          action_text: {
            view: "View",
            submited: "Submited",
            re_submit: "Re-submit",
          },
          status_text: {
            please_submit: "please submit",
            pending_approval: "pending Approval",
            approved: "APPROVED",
            rejected: "REJECTED",
          },
        },
      },
      shareholders: {
        table: {
          name: "Name of shareholder",
          email: "Email of shareholder",
          shareholder_id: "ID of shareholder",
          proof_address: "Proof of address",
          address: "Address",
          created_at: "Date Created",
          action: "Action",
        },
        table_component: {
          view: "View",
          remove: "Remove",
        },
      },
    },
    compalinaceModals: {
      confirm_shareholder_action: {
        title: "Are you sure you want to take this action?",
        subtitle:
          "You are about to remove this Shareholder, confirm if you want to continue with this action?",
        btn_actions: {
          cancel: "Cancel",
          confirm: "Yes",
        },
      },
      shareholder_form: {
        title: "Shareholder info",
        form_elements: {
          name: "Name of shareholder",
          email: "Email of shareholder",
          shareholder_id: "ID card of  shareholder",
          proof_address: "Proof of address",
          upload_support_text: "max image size 5 mb",
          address: "Address",
          phone_number: "Phone number",
          shareholder_percentage: "Share % of the shareholder",
          submit: "Submit",
        },
      },
      shareholder_kyc: {
        title: "Shareholder Created!",
        sub: "Shareholders profile has been successfully created. Click or copy the link below for the Shareholder to complete the KYC process.",
        form_elements: {
          email: "Send KYC URL to shareholder’s email",
          submit: "Send Via Mail",
        },
      },
      upload_businesss_document: {
        title: {
          upload: "Upload business document",
          update: "Change business document",
        },
        form: {
          upload_label: {
            upload: (doc_title: string) => `Upload ${doc_title}`,
            update: (doc_title: string) => `Update ${doc_title}`,
          },
          upload_supported_format_text:
            "Supported formats: JPEG, PNG, PDF, Word, max upload size is 5 mb",
          operation_license: "File Name",
          submitform: {
            submit: "Submit",
            update: "Update",
          },
        },
      },
    },
    onboardingPages: {
      termsPage: {
        title: "Payment Integration Services Agreement",
        agreement_copy:
          "This Payment Integration Services Agreement includes this introduction, Terms and Conditions, Privacy Policy, Cookie Policy, Acceptable Use Policy, Know Your Customer Policy (together the “Agreement”) forms a legal agreement",
        between_highlight: "BETWEEN",
        fuspay:
          "FusPay Technologies Inc., a private limited liability company, with its registered address at 221 W 9th st PMB 248, Wilmington Delaware, 19801]. (“FusPay” “We”, “Us”, “Our”, or the &quot;Company&quot;)",
        and_highlight: "AND",
        partner:
          "[Insert Name], a company organized and existing under the laws of [insert Jurisdiction], with its principal place of business at [Insert Your Address] (&quot;You”, “Your&quot;). Each of the parties to this Agreement shall, in addition to the pronouns, be referred to as a &quot;Party&quot; and together the Parties shall be referred to as the &quot;Parties&quot;. References made to customers, user or any third party in the Terms and Conditions, Privacy Policy, Cookie Policy, Acceptable Use Policy, Know Your Customer Policy, or any other referenced policies in this Agreement shall be construed as references to &#39;You&#39; as defined in this Agreement, unless explicitly stated otherwise.",
        whereas_highlight: "WHEREAS:",
        agree_btn: "Agree & Continue",
        scroll_down: "SCROLL DOWN",
      },
      appPreviewPage: {
        title: "App Preview",
        copy: "Demo of how FinSwich works",
      },
    },
    onboardingModals: {
      agreement_infromation: {
        agreement_title: "Agreement information",
        agreement_copy:
          "This is an automated generated SLA. Kindly put your company information and we will automatically send you the PDF version to your email.",
        authorizer_title: "Details of Authorizer",
        authorizer_copy:
          "The only acceptable “Authoriser Details” must be the name of one of the “Director” whose ID & Proof of Address will be uploaded for KYC.",
        required: "Required",
        formLabels: {
          company_name: "Company Name  (As Registered)",
          select_country: "Select Country",
          address:
            "Enter Address (We will require Proof of address to this Address)",
          authorizer_name: "Full name of Authorizer (Company Shareholder/CEO)",
          authorizer_designation:
            "Designation of Authorizer (Company Shareholder/CEO)",
          button: "Next",
        },
      },
      authorize: {
        page_title: "Sign & Authorize",
        page_subtitle:
          "This is an automated generated SLA. Kindly put your company information and we will automatically send you the PDF version to your email.",
        formLabels: {
          email: "Email to Receive signed copy of this SLA",
          enter_initials: "Enter Initial",
          read_terms:
            "I have read and agreed to the Payment Integration Service Agreement",
          signature: "Signature ID:",
          clear_field: "Clear Field",
          generate_signature: "Generate Signature",
          no_authority: {
            question: "I Don’t have the Power to Sign?",
            action: "Generate Link to Share with Authorizer",
          },
          copy_placeholder:
            "https://compliance.instance.finance/e-link/?23ujueuueue...",
          share_email: "or share via email",
          authorize_email_placeholder: "Authorizer’s email",
          check_label: {
            initial_as_signature: "Use Initial as Signature",
            draw_signature: "Draw Signature",
            upload_signature: "Upload Signature",
          },
          button: "Sign & Complete",
        },
      },
    },
    settingsPages: {
      security: {
        two_fa: {
          enable: "Enable 2Fa",
          disable: "Disable 2Fa",
        },
        update_password: {
          update_label: "Update Password",
          old_password_label: "Old Password",
          new_password_label: "New Password",
          con_password_label: "Confirm Password",
          update_button: "Update",
        },
      },
      business_profile: {
        add_profile: "Click to Add Profile",
        data: {
          type: "Business Type",
          business_type_list: [
            "Fishing",
            "Horticulture",
            "Tobacco",
            "Wood",
            "Aerospace",
            "Automotive",
            "Pharmaceutical",
            "Construction",
            "Defense",
            "Electric power",
            "Electronics",
            "Energy",
            "Food",
            "Industrial robot",
            "Low technology",
            "Meat",
            "Mining",
            "Petroleum",
            "Pulp and paper",
            "Steel",
            "Shipbuilding",
            "Telecommunications",
            "Textile",
            "Water",
            "Advertising",
            "Fashion",
            "Floral",
            "Cultural",
            "Culture",
            "Education",
            "Film",
            "Gambling",
            "Music",
            "Sex",
            "Video game",
            "Insurance",
            "Healthcare",
            "Hospitality",
            "Information",
            "Leisure",
            "Broadcasting",
            "Internet",
            "News media",
            "Publishing",
            "Entertainment",
            "Professional services",
            "Real estate",
            "Retail",
            "Software",
            "Sport",
            "Transport",
            "Banking",
            "Finance",
            "Blockchain",
            "Crypto",
            "Fintech",
          ].sort((a, b) => a.localeCompare(b)),
          address: "Address",
          description: "Description",
          country_of_operation: "Country Of Operation",
          support_email: "Support Email",
          support_phone_number: "Support Phone Number",
        },
        form_btn: {
          submit: "Submit",
          update: "Update",
        },
      },
      transaction_country: {
        add_country_label: "Active country",
        country_of_operation_label: "Country of operation",
        update_button: "Update",
      },
      activity_log: {
        table: {
          email: "Admin  email",
          action_performed: "Action performed",
          performed_on: "Performed on",
          created_at: "Time",
        },
      },
    },
    settingsModal: {
      updatePassword: {
        warn_text: "Permission is required to perform this action",
        form_label: "Enter Two Factor Authentication if you want to continue?",
        otp_label: "Enter Otp:",
        resend_otp_btn: "Resend otp",
        cancel: "Cancel",
        submit: "Submit",
      },
      toggle2fa: {
        warn_text: "Permission is required to perform this action",
        form_label: "Enter Password if you want to continue?",
        password_label: "Enter Password",
        cancel: "Cancel",
        submit: "Submit",
      },
      TwoFaPrompt: {
        warn_text: "Keep your account safe from outsiders",
        form_label:
          "Get an extra security code to confirm it is really you everytime there is a login on your account. Enable Two-Factor Authentication (2FA) to secure your account now.",

        cancel: "Ignore",
        submit: "Enable 2FA",
      },
    },
    UserPage: {
      page_title: "Admin",
      invite_user: "Invite Member",
      table: {
        email: "Member’s email",
        created_at: "Date created",
        access: "Access",
        actions: "Actions",
      },
      table_component: {
        suspend: "Suspend",
        lift_suspend: "Lift Suspension",
        manage: "Manage",
        resend_invite: "Resend Invite",
        rescind_invite: "Rescind Invite",
      },
    },
    UserModal: {
      invite_user: {
        title: {
          submit: "Invite User",
          update: "Manage User",
        },
        form: {
          member_email: "Enter member email address",
          role_label: "Choose access role",
          admin_label: "Admin",
          admin_tooltip:
            "Admins can add team members, suspend team members and change team members access",
          basic_label: "Basic User",
          basic_tooltip:
            "Team members can accept or ignore transactions in the tray area, Automatically moving request to the pending request area",
          submit: "Submit",
          update: "Update",
        },
      },
      suspend_user: {
        title: "Are you sure you want to take this action?",
        form: {
          reason_placeholder: "reason",
          warning_text: (
            email: string,
            status: boolean
          ) => ` You are about to ${status ? "suspend" : "lift"} 
          ${email}${
            status ? "" : "'s suspension"
          }, confirm if you want to continue with
          this action?`,
          cancel: "Cancel",
          accept: "Yes",
        },
      },
      rescind_invite: {
        title: "Are you sure you want to take this action?",
        form: {
          warning_text: (email: string) =>
            `You are about to rescind ${email}'s workspace invite, confirm if you want to continue with this action?`,
          cancel: "Cancel",
          accept: "Yes",
        },
      },
    },
  },
};

export default Language;
