import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "@/common/routes";
import FundWalletForm from "./FundWalletForm";
import WithdrawalThreshold from "./WithdrawalThreshold";

function VirtualCardModal() {
  return (
    <Routes>
      <Route
        path={routes.protected.applications.app.virtual_cards.threshold.index}
        element={<WithdrawalThreshold />}
      />
      <Route
        path={routes.protected.applications.app.virtual_cards.fund.index}
        element={<FundWalletForm />}
      />
    </Routes>
  );
}

export default VirtualCardModal;
