import React from "react";
import CustomCheckBox from "@/common/components/CustomCheckBox";
import * as HoverCard from "@radix-ui/react-hover-card";
import { MessageQuestion } from "../../../../static/images/icons";
// import tooltip from "../../../../static/images/icons/message-question.svg";

type Props = {
  checkHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  is_check: boolean;
  label: string;
  tip: string;
  disable: boolean;
};

function PermissionCheck({
  checkHandler,
  is_check,
  label,
  name,
  tip,
  disable,
}: Props) {
  return (
    <div className="flex items-center gap-x-4">
      <CustomCheckBox
        onChange={checkHandler}
        name={name}
        classNames={{
          label: "leading-3.5 gap-x-5 text-sm !text-teal-20",
        }}
        checked={is_check}
        label={label}
        disabled={disable}
      />
      <HoverCard.Root openDelay={0}>
        <HoverCard.Trigger>
          <span className="block h-5 w-5 cursor-pointer">
            <MessageQuestion />
          </span>
        </HoverCard.Trigger>
        <HoverCard.Portal className="">
          <HoverCard.Content
            align="center"
            side="right"
            className="HoverCardContent z-50 w-62 rounded bg-gray-520 py-4 px-2.5 text-teal-20 "
          >
            <p className="text-xs leading-5">{tip}</p>
            <HoverCard.Arrow fill="#EDEDED" />
          </HoverCard.Content>
        </HoverCard.Portal>
      </HoverCard.Root>
    </div>
  );
}

export default PermissionCheck;
