import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector, usePageTitle } from "../../../hooks";
import { AppLogo } from "../../../../static/images/icons";
import routes from "../../../routes";
import { fetchUserToken } from "../../../service/storage";
import Language from "../../../utils/language/en";
import { mergeClassName } from "../../../utils/helpers";

const { auth_layout: PageDictionary } = Language.protected.Layout;

function Auth() {
  const { pathname } = useLocation();
  const {
    user: { is_verified },
    company,
  } = useAppSelector((state) => state.auth);

  usePageTitle(PageDictionary.page_title);

  if (fetchUserToken() && is_verified && company?.admin_approved) {
    return <Navigate replace to={routes.protected.dashboard} />;
  }

  return (
    <div
      className={mergeClassName(
        pathname === routes.nonprotected.welcome ? "bg-base-40" : "bg-authBg"
      )}
    >
      {pathname === routes.nonprotected.welcome ? (
        <div className="h-screen design_screen:mx-auto  design_screen:max-w-screen-design_screen">
          <Outlet />
        </div>
      ) : (
        <div className="flex h-screen flex-col  overflow-scroll lg:flex-row design_screen:mx-auto design_screen:max-w-screen-design_screen">
          <div
            className={mergeClassName(
              "grid grid-rows-[1fr_max-content] gap-y-28 bg-base-40 px-4 pt-27.5 pb-4 sm:gap-y-0  sm:py-27.5 sm:px-16 lg:w-1/2 lg:px-24",
              pathname.includes(routes.nonprotected.paylink)
                ? "hidden lg:grid"
                : ""
            )}
          >
            <div className="flex flex-col items-center justify-center gap-y-8 text-center">
              <a href="https://instance.finance/finswich/">
                <span className="relative block text-white before:absolute before:inset-0 before:top-auto before:right-full before:h-0.5 before:bg-white before:transition-all before:duration-300 before:ease-in-out before:hover:right-0 before:hover:animate-logo_animate_body">
                  <AppLogo scale={1.5} />
                </span>
              </a>
            </div>

            {pathname.includes(routes.nonprotected.register) ? (
              <p className="self-end justify-self-center text-xs text-white md:text-base">
                By signing up, you agree to Finswich{" "}
                <a
                  href="https://instance.finance/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                  className="border-b border-white hover:border-transparent hover:text-cyan-10"
                >
                  Privacy
                </a>{" "}
                and{" "}
                <a
                  href="https://fuspay-instance.atlassian.net/wiki/x/AQB-Aw"
                  target="_blank"
                  rel="noreferrer"
                  className="border-b border-white hover:border-transparent hover:text-cyan-10"
                >
                  Terms
                </a>
              </p>
            ) : null}
            {pathname.includes(routes.nonprotected.login) ? (
              <p className="self-end justify-self-center text-xs text-white md:text-base">
                Finswich{" "}
                <a
                  href="https://instance.finance/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                  className="border-b border-white hover:border-transparent hover:text-cyan-10"
                >
                  Privacy
                </a>{" "}
                and{" "}
                <a
                  href="https://fuspay-instance.atlassian.net/wiki/x/AQB-Aw"
                  target="_blank"
                  rel="noreferrer"
                  className="border-b border-white hover:border-transparent hover:text-cyan-10"
                >
                  Terms
                </a>
              </p>
            ) : null}
          </div>
          <main className="h-full w-full bg-white lg:w-1/2">
            <Outlet />
          </main>
        </div>
      )}
    </div>
  );
}

export default Auth;
