import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import * as tp from "../types";
import { listAppTransactions } from "../../wallet/store/thunk";
import {
  getUtilityHistory,
  getUtilityRecords,
} from "../../utility/store/thunk";
import { downloadExcelStream } from "../../../common/utils/helpers";

export const getAllApps = createAsyncThunk(
  "app/getAllApps",
  async (
    req: tp.BasePaginatedRequest,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .getAllApps(req)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const getSingleApp = createAsyncThunk(
  "app/getSingleApp",
  async (
    req: tp.IpaginatedListThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .getSingleApp(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const addNewApp = createAsyncThunk(
  "app/addNewApp",
  async (req: any, { rejectWithValue, fulfillWithValue, dispatch }) => {
    return service
      .addNewApp(req)
      .then((res) => {
        dispatch(getAllApps({ page: 1, page_size: 10 }));
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const updateExistingApp = createAsyncThunk(
  "app/updateExistingApp",
  async (req: tp.Iupdateapp, { rejectWithValue, fulfillWithValue }) => {
    return service
      .updateExistingApp(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const addCustomDomainForApp = createAsyncThunk(
  "app/addCustomDomainForApp",
  async (
    req: tp.IAddCustomDomainData,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .includeAddCustomDomain(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const verifyDomainForApp = createAsyncThunk(
  "app/verifyDomainForApp",
  async (req: { appid: string }, { rejectWithValue, fulfillWithValue }) => {
    return service
      .includeVerifyCustomDomain(req)
      .then((res) => {
        return fulfillWithValue({
          app: res.data.app,
          appid: req.appid,
        }).payload;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const removeDomainForApp = createAsyncThunk(
  "app/removeDomainForApp",
  async (req: { appid: string }, { rejectWithValue, fulfillWithValue }) => {
    return service
      .removeCustomDomain(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue({
          app: res.data,
          appid: req.appid,
        }).payload;
      })
      .catch((error) => {
        customToast.success(error.message);
        return rejectWithValue(error);
      });
  }
);

export const retrieveApiKey = createAsyncThunk(
  "app/retrieveApiKey",
  async (req: tp.IApiShow, { rejectWithValue, fulfillWithValue }) => {
    return service
      .retrieveApiKey(req)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const regenerateApiKey = createAsyncThunk(
  "app/regenerateApiKey",
  async (req: tp.IApiShow, { rejectWithValue, fulfillWithValue }) => {
    return service
      .regenerateApiKey(req)
      .then((res) => {
        downloadExcelStream(res, `ApiKey-${new Date().toISOString()}`);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const goLive = createAsyncThunk(
  "app/goLive",
  async (req: tp.IgoLive, { rejectWithValue, dispatch, fulfillWithValue }) => {
    return service
      .golive(req)
      .then((res) => {
        customToast.success(res.message);
        dispatch(getSingleApp({ appid: req.appid }));
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const listAppUsers = createAsyncThunk(
  "app/users/listAppUsers",
  async (
    req: tp.IpaginatedListThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .listAppUsers(req)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const retriveAppUser = createAsyncThunk(
  "app/users/retriveAppUser",
  async (req: tp.IretriveUserThunk, { rejectWithValue, fulfillWithValue }) => {
    const { appid, userid } = req;
    return service
      .retriveAppUser(appid, userid)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const updateAppUserStatus = createAsyncThunk(
  "app/users/updateAppUserStatus",
  async (
    req: tp.IupdateUserStatusThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .updateAppUserStatus(req.appid, req.userid, req.data)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const listTransactions = createAsyncThunk(
  "app/transactions/listTransactions",
  async (req: tp.IlistTransaction, { rejectWithValue, fulfillWithValue }) => {
    return service
      .listTransactions(req)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const getTransaction = createAsyncThunk(
  "app/transactions/getTransaction",
  async (req: tp.ItxnBioData, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getTransaction(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const getAppTransaction = createAsyncThunk(
  "app/transactions/getAppTransaction",
  async (req: tp.ItxnBioData, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getAppTransaction(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const createReportTransaction = createAsyncThunk(
  "app/transactions/createReportTransaction",
  async (
    req: tp.IcreateReportTxnThunk,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    const tempstate: any = getState();
    let type: tp.IappTransaction = "DR";
    let switcher = "outgoing_trans";
    if (req.type === "CR") {
      switcher = "incoming_trans";
      type = "CR";
    }

    const filter = tempstate?.app?.transactions[switcher]?.filter;
    const pagination = tempstate?.app?.transactions[switcher]?.pagination;

    const rec_pagination = tempstate?.app?.utility?.records?.pagination;
    const his_pagination = tempstate?.app?.utility?.history?.pagination;

    return service
      .createReportTransaction(req)
      .then((res) => {
        customToast.success(res.message);
        if (req.type === "wallet") {
          dispatch(
            listAppTransactions({
              appid: req.appid,
            })
          );
        }
        if (req.type === "uhis") {
          dispatch(
            getUtilityHistory({
              appid: req.appid,
              page: his_pagination?.current_page,
              page_size: his_pagination.pagesize,
              cat: his_pagination?.filter.country?.category,
              country: his_pagination?.filter.country,
              sub_cat: his_pagination?.filter.country?.subcategory,
            })
          );
        }
        if (req.type === "urec") {
          dispatch(
            getUtilityRecords({
              appid: req.appid,
              page: rec_pagination?.current_page,
              page_size: rec_pagination.pagesize,
            })
          );
        }
        if (req.type === "CR" || req.type === "DR") {
          dispatch(
            listTransactions({
              appid: req.appid,
              page: pagination?.current_page,
              page_size: pagination.pagesize,
              type,
              search: filter.search,
            })
          );
        }
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const createPolicy = createAsyncThunk(
  "app/policy/createPolicy",
  async (req: tp.IcreatePolicy, { rejectWithValue, fulfillWithValue }) => {
    return service
      .createPolicy(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const listPolicies = createAsyncThunk(
  "app/policy/listPolicies",
  async (
    req: tp.IpaginatedListThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .listPolicies(req)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const updatePolicy = createAsyncThunk(
  "app/policy/updatePolicy",
  async (req: tp.IcreatePolicy, { rejectWithValue, fulfillWithValue }) => {
    return service
      .updatePolicy(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const retrivePolicy = createAsyncThunk(
  "app/policy/retrivePolicy",
  async (req: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .retrivePolicy(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const createUserCategory = createAsyncThunk(
  "app/policy/createUserCategory",
  async (
    req: tp.IcreateUserCategory,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .createUserCategory(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const listUserCategories = createAsyncThunk(
  "app/policy/listUserCategories",
  async (
    req: tp.IpaginatedListThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .listUserCategories(req)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const updateUserCategory = createAsyncThunk(
  "app/policy/updateUserCategory",
  async (
    req: tp.IupdateUserCategory,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .updateUserCategory(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const retriveUserCategory = createAsyncThunk(
  "app/policy/retriveUserCategory",
  async (req: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .retriveUserCategory(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const listRiskRecords = createAsyncThunk(
  "app/policy/listRiskRecords",
  async (req: tp.IriskRecord, { rejectWithValue, fulfillWithValue }) => {
    return service
      .listRiskRecords(req)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const addNotificationConfig = createAsyncThunk(
  "app/policy/addNotificationConfig",
  async (
    req: tp.InotificationConfigThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .addNotificationConfig(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const listNotificationConfig = createAsyncThunk(
  "app/policy/listNotificationConfig",
  async (
    req: Omit<tp.InotificationConfig, "nid">,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .listNotificationConfig(req)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const updateNotificationConfig = createAsyncThunk(
  "app/policy/updateNotificationConfig",
  async (
    req: tp.InotificationConfigUpdate,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .updateNotificationConfig(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const retrieveNotificationConfig = createAsyncThunk(
  "app/policy/retrieveNotificationConfig",
  async (
    req: tp.InotificationConfig,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .retrieveNotificationConfig(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const includeSwitchOn = createAsyncThunk(
  "app/includeSwitchOn",
  async (
    data: tp.IincludeSwitchOn,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const res = await service.includeSwitchOn(data);
      await dispatch(getSingleApp({ appid: data.id })).unwrap();

      customToast.success(res.message);
      return fulfillWithValue(res?.data).payload;
    } catch (err: any) {
      customToast.error(err.message);
      return rejectWithValue(err);
    }

    // return service
    //   .includeSwitchOn(data)
    //   .then((res) => {
    //     dispatch(getAllApps())
    //       .then(() => {
    //         customToast.success(res.message);
    //       })
    //       .catch((err) => {
    //         customToast.error(err.message);
    //         return rejectWithValue(err);
    //       });
    //     return fulfillWithValue(res?.data).payload;
    //   })
    //   .catch((err) => {
    //     customToast.error(err.message);
    //     return rejectWithValue(err);
    //   });
  }
);
export const includeSwitchOff = createAsyncThunk(
  "app/includeSwitchOff",
  async (
    req: tp.IincludeSwitchOffThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { app_id, password, id } = req;
    // return service
    //   .includeSwitchOff({ app_id, id })
    //   .then((res) => {
    //     customToast.success(res.message);
    //     dispatch(getAllApps());
    //     dispatch(regenerateApiKey({ appid: id, data: { password } }));
    //     return fulfillWithValue(res?.data).payload;
    //   })
    //   .catch((err) => {
    //     customToast.error(err.message);
    //     return rejectWithValue(err);
    //   });
    try {
      const res = await service.includeSwitchOff({ app_id, id });
      await dispatch(regenerateApiKey({ appid: id, data: { password } }));
      await dispatch(getSingleApp({ appid: req.id })).unwrap();

      customToast.success(res.message);
      return fulfillWithValue(res?.data).payload;
    } catch (err: any) {
      customToast.error(err.message);
      return rejectWithValue(err);
    }
  }
);
export const includePricing = createAsyncThunk(
  "app/includePricing",
  async (
    req: tp.IncludePricingPayloadType,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const res = await service.includePricing(req);
      await dispatch(getSingleApp({ appid: req.id })).unwrap();

      customToast.success(res.message);
      return fulfillWithValue(res?.data).payload;
    } catch (err: any) {
      customToast.error(err.message);
      return rejectWithValue(err);
    }
  }
);

export const createPricingPolicy = createAsyncThunk(
  "app/pricing-policy/createPricingPolicy",
  async (
    req: Record<string, any> & { appid: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const res = await service.createPricingPolicy(req);
      customToast.success(res.message);
      return fulfillWithValue(res?.data).payload;
    } catch (err: any) {
      customToast.error(err.message);
      return rejectWithValue(err);
    }
  }
);

export const listPricingPolicies = createAsyncThunk(
  "app/pricing-policy/listPricingPolicies",
  async (req: { appid: string }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await service.listPricingPolicies(req);
      // customToast.success(res.message);
      return fulfillWithValue(res?.data).payload;
    } catch (err: any) {
      customToast.error(err.message);
      return rejectWithValue(err);
    }
  }
);

export const getPricingPolicy = createAsyncThunk(
  "app/pricing-policy/getPricingPolicy",
  async (
    req: { appid: string; pricingid: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const res = await service.getPricingPolicy(req);
      customToast.success(res.message);
      return fulfillWithValue(res?.data).payload;
    } catch (err: any) {
      customToast.error(err.message);
      return rejectWithValue(err);
    }
  }
);

export const updatePricingPolicy = createAsyncThunk(
  "app/pricing-policy/updatePricingPolicy",
  async (
    req: Partial<Record<string, any>> & { appid: string; pricingid: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const res = await service.updatePricingPolicy(req);
      customToast.success(res.message);
      return fulfillWithValue(res?.data).payload;
    } catch (err: any) {
      customToast.error(err.message);
      return rejectWithValue(err);
    }
  }
);

export const deletePricingPolicy = createAsyncThunk(
  "app/pricing-policy/deletePricingPolicy",
  async (
    req: { appid: string; pk: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const res = await service.deletePricingPolicy(req);
      customToast.success(res.message);
      return fulfillWithValue(res?.data).payload;
    } catch (err: any) {
      customToast.error(err.message);
      return rejectWithValue(err);
    }
  }
);
