import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { transferFormType, resolver } from "./schema";
import {
  defaultValueHandler,
  optionsHandler,
  transferFormFeilds,
} from "./helper";
import { Form, FormItem } from "@/common/components/Form";
import { Input } from "@/common/components/Form/elements/Input";
import Select from "@/common/components/Form/elements/Select";
import CustomButton from "@/common/components/CustomButton";
import { useAppDispatch } from "@/common/hooks";
import { differenceHandler } from "@/common/utils/helpers";
import {
  createPricingPolicy,
  updatePricingPolicy,
} from "@/module/applications/store/thunk";
import OtherInfo from "../OtherInfo";
import { PricingPolicyFormProps } from "../types";

function TransferForm({
  initialData,
  otherData,
  loading,
  completedHandler,
}: PricingPolicyFormProps) {
  const form = useForm<transferFormType>({
    resolver,
    defaultValues: defaultValueHandler(initialData),
  });
  const dispatch = useAppDispatch();

  const value_type = form.watch("value_type");
  const with_cap = form.watch("with_cap");
  const hideFields: Array<keyof transferFormType> = ["lower_cap", "upper_cap"];

  useEffect(() => {
    if (value_type === "FLAT") {
      form.setValue("with_cap", "false");
    }

    if (with_cap === "false") {
      const condition = {
        shouldDirty: false,
        shouldTouch: false,
      };
      hideFields.forEach((key) => {
        form.setValue(key, undefined, condition);
      });
    }
  }, [with_cap, value_type]); //eslint-disable-line

  const submitHandler = (data: transferFormType) => {
    const temp = { ...data, with_cap: JSON.parse(data.with_cap) as boolean };
    const payload = differenceHandler(temp, initialData);

    const action = initialData?._id
      ? updatePricingPolicy({
          pricingid: initialData?._id,
          ...otherData,
          ...payload,
        })
      : createPricingPolicy({
          ...otherData,
          ...payload,
        });

    dispatch(action).unwrap().finally(completedHandler);
  };

  return (
    <Form<transferFormType>
      {...form}
      form={form}
      onSubmit={submitHandler}
      className="grid grid-cols-2 gap-x-7 gap-y-8 lg:pr-7"
    >
      {transferFormFeilds
        .filter((field) => {
          if (value_type === "FLAT" && field.name === "with_cap") return false;
          if (with_cap === "false" && hideFields.includes(field.name)) {
            return false;
          }

          return true;
        })
        .map((field) => (
          <FormItem
            key={field.name}
            name={field.name}
            label={field.label}
            required={field?.rules?.required}
            classNames={{
              label: "text-sm leading-6 text-[#090914] mb-2.5",
            }}
            render={(otherProps) => {
              return (
                <>
                  {field.type === "select" ? (
                    <Select
                      {...otherProps}
                      placeholder={field.placeHolder}
                      data={optionsHandler(field.name)}
                    />
                  ) : (
                    <Input
                      {...otherProps}
                      placeholder={field.placeHolder}
                      suffix={
                        value_type === "FLAT" && field?.suffixIcon
                          ? "$"
                          : field.suffixIcon
                      }
                    />
                  )}
                  <OtherInfo data={field?.otherInfo} />
                </>
              );
            }}
          />
        ))}
      <CustomButton
        className="col-start-1 justify-self-start"
        isloading={loading}
        disabled={!form.formState.isDirty}
      >
        {initialData?._id ? "Update" : "Submit"}
      </CustomButton>
    </Form>
  );
}

export default TransferForm;
