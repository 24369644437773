import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import CenterModal from "@/common/components/CustomModal/CenterModal";
import CustomTextArea from "@/common/components/CustomTextArea";
import CustomButton from "@/common/components/CustomButton";
import { updateAccountStatus } from "../../store/thunk";
import { iHandleSubmit } from "../../types";
import Language from "@/common/utils/language/en";

const { suspend_user: PageDictionary } = Language.protected.UserModal;

function SuspendUser() {
  const { uid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState("");
  const { loading } = useAppSelector((state) => state.user);

  const active_status = location.state?.data?.is_active;
  const email = location.state?.data?.email;

  const changeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setReason(value);
  };

  const cancelHandler = () => {
    if (loading) return;
    navigate(location.state.background.pathname, {
      replace: true,
      state: null,
    });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!uid) return;
    if (!location.state?.data) return;
    dispatch(
      updateAccountStatus({
        member_id: uid,
        data: { is_active: !active_status, reason },
      })
    )
      .unwrap()
      .finally(cancelHandler);
  };

  return (
    <CenterModal className="pt-26 pb-32" handleClose={cancelHandler}>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-blue_gray-10 text-2xl">{PageDictionary.title}</h3>
        {/* <WarningMessage email={email} status={active_status} /> */}
        <p className="text-sm text-red-40">
          {PageDictionary.form.warning_text(email, active_status)}
        </p>
        <CustomTextArea
          placeholder={PageDictionary.form.reason_placeholder}
          cols={56}
          rows={4}
          onChange={changeHandler}
          labelClassName="hidden mb-0"
        />
        <div className="flex items-center gap-x-9">
          <CustomButton
            disabled={loading}
            variant
            type="button"
            onClick={cancelHandler}
          >
            {PageDictionary.form.cancel}
          </CustomButton>
          <CustomButton disabled={reason === ""} isloading={loading}>
            {PageDictionary.form.accept}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default SuspendUser;
