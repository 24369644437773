import cuid from "cuid";
import Language from "@/common/utils/language/en";
import { Iapp, IappchannelChecklist, IcreateAppState } from "../../../types";
import { removeUnderScoreAddSpace } from "@/common/utils/helpers";

const { channels: PageDictionary } =
  Language.protected.CreateApplicationModal.CreateApplicationForm.form
    .app_channels;

export const header_btn: IappchannelChecklist[] = [
  { name: "ios", label: PageDictionary.ios },
  { name: "web", label: PageDictionary.web },
  { name: "andriod", label: PageDictionary.andriod },
];

export const initialstate = {
  channels: { ios: false, andriod: false, web: false },
  name: "",
  parent_company: "",
  logo: [],
  parent_company_logo: [],
  industry: "",
  description: "",
  application_url: "",
  user_base: "",
  confirm_kyc: false,
};

export const errorstate = {
  channels: false,
  name: false,
  parent_company: false,
  logo: false,
  parent_company_logo: false,
  industry: false,
  description: false,
  application_url: false,
  user_base: false,
  confirm_kyc: false,
};

export const handleRestoreData = (
  state: IcreateAppState,
  setState: React.Dispatch<React.SetStateAction<IcreateAppState>>,
  restoreAppInfo: Iapp
) => {
  (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
    switch (key) {
      case "channels":
        const [ch1, ch2, ch3] = restoreAppInfo?.channels;
        setState((prev) => ({
          ...prev,
          [key]: {
            ios: ch1?.status ? true : false,
            andriod: ch2?.status ? true : false,
            web: ch3?.status ? true : false,
          },
        }));
        break;
      case "logo":
      case "parent_company_logo":
        setState((prev) => ({
          ...prev,
          [key]: restoreAppInfo[`${key}_url`]
            ? [
                {
                  id: cuid(),
                  src: restoreAppInfo[`${key}_url`],
                  name: removeUnderScoreAddSpace(key).toLowerCase(),
                  url: restoreAppInfo[`${key}_url`],
                },
              ]
            : [],
        }));
        break;
      case "confirm_kyc":
        break;
      default:
        setState((prev) => ({
          ...prev,
          [key]: restoreAppInfo[key] ? restoreAppInfo[key] : "",
        }));
        break;
    }
  });
};

export const diffrenceHandler = (
  state: IcreateAppState,
  restoreAppInfo?: Iapp
) => {
  let diffrence: any = {};
  let count = 0;
  if (!restoreAppInfo) return { diffrence, count };
  const convertArrToChannelObj = (params: any[]): Record<string, any> => {
    let temp: Record<string, any> = {};
    let paramsdata = params;

    if (typeof params === "string") {
      paramsdata = JSON.parse(params);
    }
    paramsdata?.forEach((param) => {
      temp[param.name.toLowerCase()] = param.status;
    });

    return temp;
  };

  (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
    switch (key) {
      case "channels":
        if (restoreAppInfo.channels.length === 0) {
          return;
        }
        if (
          JSON.stringify(state.channels) !==
          JSON.stringify(convertArrToChannelObj(restoreAppInfo.channels))
        ) {
          const tempo = (
            Object.keys(state.channels) as Array<keyof typeof state.channels>
          ).map((c_key) => ({
            name: c_key.toUpperCase(),
            status: state.channels[c_key] ? state.channels[c_key] : false,
          }));
          count++;
          diffrence[key] = JSON.stringify(tempo);
        }

        break;
      case "logo":
      case "parent_company_logo":
        if (
          state[key][0]?.url === undefined &&
          restoreAppInfo[`${key}_url`] === ""
        ) {
          return;
        }
        if (state[key][0]?.url !== restoreAppInfo[`${key}_url`]) {
          count++;
          diffrence[key] = state[key][0]?.url;
        }
        break;
      case "confirm_kyc":
        break;
      default:
        if (state[key] !== restoreAppInfo[key]) {
          count++;
          diffrence[key] = state[key];
        }
        break;
    }
  });

  return { diffrence, count };
};
