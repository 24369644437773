import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import transactionMiddleware from "../module/wallet/store/transactionMiddleware";
import rootReducer from "./rootreducer";
import { injectStore } from "../common/service/request";
import errorMiddleware from "./helperReducers/errorMiddleware";
import { virtualCardApi } from "@/module/virtualcards/store/api";
import { collectionApi } from "@/module/paylink/service/api";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      collectionApi.middleware,
      virtualCardApi.middleware,
      transactionMiddleware,
      errorMiddleware,
    ]),
  devTools: process.env.NODE_ENV !== "production",
});
let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootStore = typeof store;
injectStore(store);

const sups = { store, persistor };

export default sups;
