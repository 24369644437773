import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "@/common/components/CustomButton";
import CenterModal from "@/common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import {
  deactivateCryptoAddress,
  updateBankAccount,
} from "../../../store/thunk";
import { iHandleSubmit } from "../../../types";
import Language from "@/common/utils/language/en";

const { DeactivateAccount: PageDictionary } =
  Language.protected.walletModals.withdrawalSetting;

function DeactivateAccount() {
  const { accid, appid } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    loading,
    data: { bank_account },
  } = useAppSelector((state) => state.wallet.withdrawal);
  const is_active = state?.is_active;
  const is_activeAccount = bank_account.find(
    (account) => account.is_active === true
  );

  const cancelHandler = () => {
    if (loading) return;
    navigate(state.background.pathname, {
      replace: true,
      state: null,
    });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!accid || !appid) return;

    if (state?.background?.pathname?.includes("crypto")) {
      const req = {
        appid,
        wid: accid,
      };
      dispatch(deactivateCryptoAddress(req)).unwrap().finally(cancelHandler);
    }
    if (state?.background?.pathname?.includes("bank")) {
      const req = {
        appid,
        bankid: accid,
        data: {
          is_active: !is_active,
        },
      };
      if (is_activeAccount && accid !== is_activeAccount._id) {
        const temp = {
          appid,
          bankid: is_activeAccount._id,
          data: { is_active: false },
        };
        dispatch(updateBankAccount(temp))
          .unwrap()
          .then(() =>
            dispatch(updateBankAccount(req)).unwrap().finally(cancelHandler)
          )
          .catch(cancelHandler);
        return;
      }
      dispatch(updateBankAccount(req)).unwrap().finally(cancelHandler);
    }
  };

  return (
    <CenterModal className="pt-26 pb-32" handleClose={cancelHandler}>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-blue_gray-10 text-2xl">{PageDictionary.title}</h3>
        <p className="text-sm text-red-40">
          {is_active
            ? PageDictionary.subtitle_deactivate
            : PageDictionary.subtitle_activate}
        </p>
        <div className="flex items-center gap-x-9">
          <CustomButton variant onClick={cancelHandler}>
            {PageDictionary.cancel}
          </CustomButton>
          <CustomButton isloading={loading}>
            {PageDictionary.accept}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default DeactivateAccount;
