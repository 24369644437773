import React from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@/common/hooks";
import customToast from "@/common/components/CustomToast";
import routes from "@/common/routes";
import Language from "@/common/utils/language/en";

type props = {
  is_bank?: boolean;
  amount: string;
};

const PageDictionary = Language.protected.Layout.wallet_withdrawal;

const links = [
  {
    link: routes.protected.applications.app.wallet.withdrawl.nav,
    title: PageDictionary.bank_transfer,
  },
  {
    link: routes.protected.applications.app.wallet.withdrawl.authorize_crypto
      .nav,
    title: PageDictionary.crypto,
  },
];
function ModalNavLinkWithdrawal({ is_bank, amount }: props) {
  const { appid } = useParams();
  const state = useLocation().state;
  const navigate = useNavigate();
  const crypto = useAppSelector((state) => state.wallet.withdrawal.data.crypto);

  const navClickHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!appid) return;

    let path = "";
    if (amount === "") {
      return;
    }

    switch (true) {
      case e.currentTarget.href.includes(
        routes.protected.applications.app.wallet.withdrawl.nav
      ):
        path = routes.protected.applications.app.wallet.withdrawl.link(appid);
        break;

      default:
        path =
          routes.protected.applications.app.wallet.withdrawl.authorize_crypto.link(
            appid
          );
        if (!crypto?._id) {
          customToast.error(PageDictionary.no_crypto_withdrawal_details);
          navigate(state?.background?.pathname);
          return;
        }
        break;
    }

    navigate(path, { state: { ...state, amount } });
  };

  return (
    <div className="flex items-center gap-x-6">
      {links.map((itm) => {
        if (is_bank && itm.title === PageDictionary.crypto) {
          return null;
        }

        return (
          <NavLink
            key={itm.title}
            onClick={navClickHandler}
            className={({ isActive }) =>
              isActive
                ? " inline-block min-w-[6.5rem] rounded border border-black bg-black px-2.5 py-2 text-center text-sm font-medium text-white"
                : " inline-block min-w-[6.5rem] rounded border border-gray-360 bg-white px-2.5 py-2 text-center text-sm font-medium text-gray-530"
            }
            to={itm.link}
          >
            {itm.title}
          </NavLink>
        );
      })}
    </div>
  );
}

export default ModalNavLinkWithdrawal;
