import React, { useEffect } from "react";
import SideModal from "@/common/components/CustomModal/SideModal";
import { useParams } from "react-router-dom";
import CustomCarousel from "@/common/components/CustomCarousel";
import { slides } from "./helper";
import useNavigateModal from "@/common/hooks/useNavigateModal";
import customToast from "@/common/components/CustomToast";
import { cards } from "../../../pages/Include/Templates";

export function ViewTemplate() {
  const templateType = useParams()?.templateType as keyof typeof slides;
  const navigate = useNavigateModal();

  const card = cards.find((item) => item.type === templateType);

  useEffect(() => {
    if (!templateType) {
      customToast.error("Invalid Selection");
      navigate();
    }
  }, [templateType]); //eslint-disable-line

  const slide = slides[templateType].map((item, index) => (
    <img
      src={item}
      className="max-h-[21rem] w-[13rem]"
      alt={`${index} slide for ${templateType}`}
    />
  ));

  return (
    <SideModal className="flex flex-col gap-y-7 px-9 pt-28 pb-9">
      <header className="flex flex-col gap-y-3">
        <h3 className="mb-1 text-3236 font-extrabold leading-7 text-[#372A59]">
          {card?.title}
        </h3>
        <p className="max-w-[15rem] text-xs font-medium text-[#392943]">
          {card?.sub}
        </p>
      </header>
      <div>
        <CustomCarousel
          slides={slide}
          className="mr-auto h-auto max-h-96 max-w-2xl"
        />
      </div>
    </SideModal>
  );
}
