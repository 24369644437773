import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { amountValidation } from "@/common/utils/helpers";

const kycFormSchema = z.object({
  charge_kyc: z.string({ message: "Charge KYC is required" }),
  markup: amountValidation("KYC Pricing").transform(Number),
  kyc_charge_count: amountValidation("Split Charges")
    .optional()
    .transform((val) => (val ? Number(val) : undefined)),
  charging_cycle: amountValidation("Charging cycle")
    .optional()
    .transform((val) => (val ? Number(val) : undefined)),
  narration: z.string({ message: "Narration is required" }).optional(),
});

export const resolver = zodResolver(kycFormSchema);

export type kycFormType = z.infer<typeof kycFormSchema>;
