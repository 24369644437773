import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, useEffectOnce } from "@/common/hooks";
import SideModal from "@/common/components/CustomModal/SideModal";
import ModalTitleHeader from "@/common/components/ModalTitleHeader";
import CustomButton from "@/common/components/CustomButton";
import {
  FormatNumber,
  addCommas,
  removeNonNumeric,
  toCurrency,
} from "@/common/utils/helpers";

import Language from "@/common/utils/language/en";
import { IthresholdData } from "@/module/utility/types";
import { iHandleChange, iHandleSubmit } from "@/common/types";
import { setWithdrawalThreshold } from "@/module/utility/store/thunk";

const { WithdrawalThreshold: PageDictionary } =
  Language.protected.walletModals.withdrawal;

const initialstate = {
  amount: "",
  email: "",
};

const differenceHandler = (
  localData: typeof initialstate,
  globalData: IthresholdData | null
) => {
  type res = {
    count: number;
    difference: any;
  };

  let response: res = {
    count: 0,
    difference: {},
  };

  if (!globalData) return response;

  const keys = Object.keys(localData) as Array<keyof typeof localData>;
  keys.forEach((key) => {
    switch (key) {
      case "amount":
        if (globalData[key] && localData[key] !== globalData[key].toString()) {
          response.count++;
          response.difference[key] = localData[key];
        }
        break;

      default:
        if (localData[key] !== globalData[key]) {
          response.count++;
          response.difference[key] = localData[key];
        }
        break;
    }
  });
  return response;
};

function WithdrawalThreshold() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation().state?.background;
  const [inData, setInData] = useState(initialstate);
  const { data: threshold, loading } = useAppSelector(
    (state) => state.utility.theshold
  );
  const dispatch = useAppDispatch();

  const { count } = differenceHandler(inData, threshold);

  const disable_submit = !threshold && Object.values(inData).includes("");
  const disable_update = !!threshold && count === 0;

  useEffectOnce(() => {
    if (!threshold) {
      return;
    }
    setInData((prev) => ({
      ...prev,
      // amount: threshold?.amount ? parseInt(threshold?.amount).toString() : "0",
      amount: FormatNumber(threshold.amount),
      email: threshold.email,
    }));
  });

  const backdropHandler = () => {
    if (loading) return;
    navigate(-1);
  };

  const changeHandler: iHandleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      setInData((prev) => ({
        ...prev,
        [name]: addCommas(removeNonNumeric(value)),
      }));
    }
    if (name !== "amount") {
      setInData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!appid) return;

    const req = {
      appid,
      data: { ...inData, amount: removeNonNumeric(inData.amount) },
    };

    dispatch(setWithdrawalThreshold(req))
      .unwrap()
      .then(() => navigate(location.pathname));

    // if (threshold) {
    //   dispatch(updateThreshold({ ...req, threshid: threshold._id }))
    //     .unwrap()
    //     .then(() => navigate(location.pathname));
    // }
  };

  return (
    <SideModal
      onclickBackDrop={backdropHandler}
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <ModalTitleHeader
        title={
          threshold?._id
            ? PageDictionary.title.update
            : PageDictionary.title.set
        }
        subtitle={PageDictionary.subtitle}
      />
      <form className="flex flex-col gap-y-10 pt-11" onSubmit={submitHandler}>
        <div>
          <label className="mb-5 inline-flex flex-col" htmlFor="balance">
            <span className="font-bold">{PageDictionary.form.alert.label}</span>
            <span className="text-sm text-gray-410">
              {PageDictionary.form.alert.sub}
            </span>
          </label>
          <div className="relative">
            <span className="absolute left-5 top-1/2 -translate-y-1/2 text-2xl font-medium text-gray-450">
              {toCurrency(0).split("0.00")}
            </span>
            <input
              type="text"
              id="balance"
              name="amount"
              className="h-[3.75rem] w-full rounded-xl border border-blue-50 bg-inputbg pt-3 pb-3 pl-11 text-black placeholder:text-base placeholder:text-gray-600"
              value={inData.amount}
              onChange={changeHandler}
              readOnly={loading || threshold?._id !== undefined}
            />
          </div>
        </div>
        <div>
          <label className="mb-5 inline-flex flex-col" htmlFor="email">
            <span className="font-bold">{PageDictionary.form.email.label}</span>
            <span className="text-sm text-gray-410">
              {PageDictionary.form.email.sub}
            </span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="h-[3.75rem] w-full rounded-xl border border-blue-50 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
            value={inData.email}
            onChange={changeHandler}
            readOnly={loading || threshold?._id !== undefined}
          />
        </div>
        {threshold?._id ? null : (
          <CustomButton
            disabled={disable_submit || disable_update}
            isloading={loading}
            className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
          >
            {threshold
              ? PageDictionary.form.update_btn
              : PageDictionary.form.submit_btn}
          </CustomButton>
        )}
      </form>
    </SideModal>
  );
}

export default WithdrawalThreshold;
