import { FieldTypes } from "@/common/components/Form/type";
import { kycFormType } from "./schema";
import { PricingPolicyType } from "@/module/applications/types";

export const transferFormFeilds: FieldTypes<kycFormType>[] = [
  {
    name: "charge_kyc",
    label: "Charge KYC",
    type: "select",
    otherInfo: "Select if charge applies or not",
  },
  {
    name: "markup",
    label: "KYC Pricing",
    otherInfo: "Cost to charge user for  KYC",
    prefixIcon: "$",
  },
  {
    name: "kyc_charge_count",
    label: "Split Charges into trickles",
    otherInfo: "How many times you want to charge user",
    suffixIcon: "Times",
  },
  {
    name: "charging_cycle",
    label: "Charging cycle",
    otherInfo: "Charges will occur on every cycle",
    suffixIcon: "DAYS",
  },
  {
    name: "narration",
    label: "Narration",
    placeHolder: "Describe narration for debit",
  },
];

export const defaultValues: kycFormType = {
  charge_kyc: "true",
  markup: 0,
  kyc_charge_count: 0,
  charging_cycle: 0,
  narration: "",
};

const chargeOptions = [
  { label: "Apply Charge", value: "true" },
  { label: "No Charges", value: "false" },
];

export const optionsHandler = (name: string) => {
  if (name === "charge_kyc") {
    return chargeOptions;
  }

  return [];
};

export const defaultValueHandler = (data?: Partial<PricingPolicyType>) => {
  return {
    ...(data || defaultValues),
    charge_kyc:
      typeof data?.charge_kyc === "boolean"
        ? JSON.stringify(data?.charge_kyc)
        : defaultValues.charge_kyc,
  };
};
