import { lazy } from "react";
import routes from ".";
import { Iroute } from "../types";

const loadModules = (module: string, link: string) =>
  lazy(() => import(`../../module/${module}/pages/${link}`));

const route: Iroute[] = [
  {
    path: routes.nonprotected.login,
    Component: loadModules("auth", "Login"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.register,
    Component: loadModules("auth", "Register"),
    access: "guest-only",
  },
  // {
  //   path: routes.nonprotected.two_fa,
  //   Component: loadModules("auth", "TwoFA"),
  //   access: "guest-only",
  // },
  {
    path: routes.nonprotected.resetpassword,
    Component: loadModules("auth", "RestPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.forgotpassword,
    Component: loadModules("auth", "ForgotPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.verified,
    Component: loadModules("auth", "EnterOtp"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.enter_otp,
    Component: loadModules("auth", "EnterOtp"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.verify_with_2fa,
    Component: loadModules("auth", "EnterOtp"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.new_password,
    Component: loadModules("auth", "NewPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.reset_successful,
    Component: loadModules("auth", "ResetSuccessful"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.paylink,
    Component: loadModules("paylink", "Payment"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.welcome,
    Component: loadModules("auth", "Welcome"),
    access: "guest-only",
  },
  {
    path: routes.protected.dashboard,
    Component: loadModules("dashboard", "Welcome"),
    access: "loggedin-user",
  },
  {
    path: routes.protected.white_label.plans,
    Component: loadModules("whitelabel", "Plan"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.white_label.active_subscription,
    Component: loadModules("whitelabel", "ActiveSubscription"),
    access: "loggedin-user",
  },
  {
    path: routes.protected.white_label.merchants,
    Component: loadModules("whitelabel", "Merchants"),
    access: "loggedin-user",
  },
  {
    path: routes.protected.applications.list,
    Component: loadModules("applications", "Application"),
    access: "loggedin-user",
  },
  // {
  //   path: routes.protected.applications.transaction_information.index,
  //   Component: loadModules("applications", "TransactionInformation"),
  //   access: "loggedin-user",
  // },
  {
    path: routes.protected.applications.app.transactions.incomingTx.index,
    Component: loadModules("applications", "IncomingSettlement"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.transactions.outgoingTx.index,
    Component: loadModules("applications", "OutgoingTx"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.risk_control.policy.index,
    Component: loadModules("applications", "Control/Policy"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.risk_control.categories.index,
    Component: loadModules("applications", "Control/Categories"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.risk_control.risks.risk_record
      .index,

    Component: loadModules("applications", "RiskRecord"),
    access: "loggedin-user",
    layout: "policy",
  },
  {
    path: routes.protected.applications.app.risk_control.risks
      .notification_config.index,
    Component: loadModules("applications", "NotificationConfig"),
    access: "loggedin-user",
    layout: "policy",
  },
  {
    path: routes.protected.applications.app.include.url.index,
    Component: loadModules("applications", "Include/Url"),
    access: "loggedin-user",
    layout: "include",
  },
  {
    path: routes.protected.applications.app.include.config.index,
    Component: loadModules("applications", "Include/Config"),
    access: "loggedin-user",
    layout: "include",
  },
  // {
  //   path: routes.protected.applications.app.include.pricing.index,
  //   Component: loadModules("applications", "Include/Pricing"),
  //   access: "loggedin-user",
  //   layout: "include",
  // },
  // {
  //   path: routes.protected.applications.app.include.wallet.index,
  //   Component: loadModules("applications", "Include/Wallet"),
  //   access: "loggedin-user",
  //   layout: "include",
  // },
  {
    path: routes.protected.applications.app.include.index,
    Component: loadModules("applications", "Include/Wallet"),
    access: "loggedin-user",
    layout: "include",
  },
  {
    path: routes.protected.applications.app.include.template.index,
    Component: loadModules("applications", "Include/Templates"),
    access: "loggedin-user",
    layout: "include",
  },
  {
    path: routes.protected.applications.app.users.index,
    Component: loadModules("applications", "Users"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.api_settings.index,
    Component: loadModules("applications", "ApiSettings"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.api_settings.api_keys.index,
    Component: loadModules("applications", "ApiSettings/ApiKeys"),
    access: "loggedin-user",
    layout: "api-setting",
  },
  {
    path: routes.protected.applications.app.api_settings.prod_webhooks.index,
    Component: loadModules("applications", "ApiSettings/ProdWebhooks"),
    access: "loggedin-user",
    layout: "api-setting",
  },
  {
    path: routes.protected.applications.app.api_settings.test_webhooks.index,
    Component: loadModules("applications", "ApiSettings/TestWebhooks"),
    access: "loggedin-user",
    layout: "api-setting",
  },
  {
    path: routes.protected.applications.app.checkout_settings.services.index,
    Component: loadModules("applications", "CheckoutSettings/Service"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.checkout_settings.branding.index,
    Component: loadModules("applications", "CheckoutSettings/Branding"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.vas_services.utility.history.index,
    Component: loadModules("utility", "UtilityHistory"),
    access: "loggedin-user",
    layout: "utility",
  },
  {
    path: routes.protected.applications.app.vas_services.utility.records.index,
    Component: loadModules("utility", "WalletRecords"),
    access: "loggedin-user",
    layout: "utility",
  },
  {
    path: routes.protected.applications.app.wallet.index,
    Component: loadModules("wallet", "Wallet"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.analytics.index,
    Component: loadModules("applications", "Analytics"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.app.wallet.settings.withdrawal_details
      .bank.index,
    Component: loadModules("wallet", "WithdrawalSettingBank"),
    access: "loggedin-user",
    layout: "withdrawal-setting",
  },
  {
    path: routes.protected.applications.app.wallet.settings.withdrawal_details
      .crypto.index,
    Component: loadModules("wallet", "WithdrawalSettingCrypto"),
    access: "loggedin-user",
    layout: "withdrawal-setting",
  },
  {
    path: routes.protected.applications.app.virtual_cards.wallet_record.index,
    Component: loadModules("virtualcards", "Records"),
    access: "loggedin-user",
    layout: "virtual-card",
  },
  {
    path: routes.protected.applications.app.virtual_cards.cards_record.index,
    Component: loadModules("virtualcards", "Cards"),
    access: "loggedin-user",
    layout: "virtual-card",
  },
  {
    path: routes.protected.applications.app.virtual_cards.transactions_record
      .index,
    Component: loadModules("virtualcards", "Transactions"),
    access: "loggedin-user",
    layout: "virtual-card",
  },
  // {
  //   path: routes.protected.applications.app.virtual_cards.pricing.index,
  //   Component: loadModules("virtualcards", "Pricing"),
  //   access: "loggedin-user",
  //   layout: "virtual-card",
  // },
  {
    path: routes.protected.compliance.business_document.index,
    Component: loadModules("compliance", "BusinessDocument"),
    access: "loggedin-user",
    layout: "compliance",
  },
  {
    path: routes.protected.compliance.shareholders.index,
    Component: loadModules("compliance", "Shareholders"),
    access: "loggedin-user",
    layout: "compliance",
  },
  {
    path: routes.protected.compliance.bussiness_profile,
    Component: loadModules("compliance", "BusinessProfile"),
    access: "loggedin-user",
    layout: "compliance",
  },
  {
    path: routes.protected.settings.security,
    Component: loadModules("settings", "Security"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.business_profile,
    Component: loadModules("settings", "BusinessProfile"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.transaction_country,
    Component: loadModules("settings", "TransactionCountry"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.activity_log,
    Component: loadModules("settings", "ActivityLog"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.user.index,
    Component: loadModules("user", "User"),
    access: "loggedin-user",
  },
  {
    path: routes.protected.onboarding.terms.index,
    Component: loadModules("onboarding", "Terms"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.preview_app,
    Component: loadModules("onboarding", "PreviewApp"),
    access: "public",
  },
  {
    path: routes.protected.onboarding.business_document.index,
    Component: loadModules("compliance", "BusinessDocument"),
    access: "loggedin-user",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.bussiness_profile,
    Component: loadModules("compliance", "BusinessProfile"),
    access: "loggedin-user",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.shareholders.index,
    Component: loadModules("compliance", "Shareholders"),
    access: "loggedin-user",
    layout: "onboarding",
  },
];

export default route;
