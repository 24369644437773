import React, { Fragment } from "react";
import SideModal from "@/common/components/CustomModal/SideModal";
import { useCopyToClipboard } from "@/common/hooks";
import CustomButton from "@/common/components/CustomButton";

const info = [
  {
    val: "Open your Crypto App",
  },
  {
    val: "Copy the address below, make a deposit using TRC20.",
    details: {
      address: "sjfbipdsobdfbdojfbdfobdjfbojdbofeob",
      network: "Trc20",
      amount: "$4,000.00",
    },
  },
  {
    val: "Click I’ve Paid and we will confirm your payment and your",
  },
];

const PlanInfo = ({
  title,
  value,
  canCopy,
}: {
  title: string;
  value?: string;
  canCopy?: boolean;
}) => {
  const { copyToClipboard } = useCopyToClipboard();
  const copyHandler = () => {
    if (!value) return;
    copyToClipboard(value);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <p className="text-xl font-medium leading-4 text-[#353536]">{title}</p>
      <div className="flex items-center gap-x-4">
        <p className="text-2826 font-semibold text-[#343131]">{value}</p>
        {canCopy ? (
          <button
            onClick={copyHandler}
            className="w-max rounded-10 bg-[#0DFF0026] px-5 py-0.5 text-center text-[#286C31]"
          >
            Copy
          </button>
        ) : null}
      </div>
    </div>
  );
};

function BuyPlan() {
  const completeHandler = () => {
    alert("click on backdrop to close");
  };
  return (
    <SideModal className="flex flex-col gap-y-15 p-8 md:pt-15 md:pl-14 md:pr-28 ">
      <header className="flex flex-col gap-y-3">
        <h3 className="text-4xl">Purchase a Reseller Plan</h3>
        <p>Set up a merchant to have access to your app.</p>
      </header>

      <div>
        <ol className="flex list-inside list-decimal flex-col gap-y-3 text-[#A2602D]">
          {info.map((item, index) => (
            <Fragment key={index}>
              <li>{item.val}</li>
              {item.details?.address ? (
                <div className="flex flex-col gap-y-6 rounded-3xl bg-[#F6F6F670] py-3.5 px-5">
                  <PlanInfo
                    title="Address"
                    value={item.details?.address}
                    canCopy
                  />
                  <PlanInfo title="Network" value={item.details?.network} />
                  <PlanInfo
                    title="Amount"
                    value={item.details?.amount}
                    canCopy
                  />
                </div>
              ) : null}
            </Fragment>
          ))}
        </ol>
      </div>
      <CustomButton onClick={completeHandler} className="self-start">
        I’ve Paid
      </CustomButton>
    </SideModal>
  );
}

export default BuyPlan;
