import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useAppDispatch,
  // useAppSelector,
  useCountDown,
} from "@/common/hooks";
import SideModal from "@/common/components/CustomModal/SideModal";
import customToast from "@/common/components/CustomToast";
// import { walletActions } from "../../../../store/slice";
import {
  fundWalletPolling,
  // listAppTransactions,
} from "../../../../store/thunk";
import loadingGif from "../../../../../../static/images/loading.gif";
import routes from "@/common/routes";
import Language from "@/common/utils/language/en";
import { mergeClassName } from "@/common/utils/helpers";

const { PaymentProcessing: PageDictionary } =
  Language.protected.walletModals.funding;

function PaymentPrcocessing() {
  const { appid } = useParams();

  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useSearchParams()[0].get("stage") || "0";
  const stage = parseInt(params);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState<Record<string, string>>({});
  const dispatch = useAppDispatch();
  // console.log(stage);

  const transactionSuccessfull = (account: Record<string, string>) => {
    if (!appid) return;

    if (account.payment_status === "pending" && stage === 1) {
      const path = routes.protected.applications.app.wallet.fund.bank.link(
        appid,
        "new"
      );
      navigate(path, { state: { ...state, account } });
    }
    if (account.payment_status === "paid" && stage === 2) {
      const path =
        routes.protected.applications.app.wallet.fund.success.link(appid);
      navigate(path, { state: { ...state, account } });
    }
  };
  const transactionFailed = () => {
    if (!appid) return;
    customToast.error("Error was encountered");
    const path = routes.protected.applications.app.wallet.link(appid);
    navigate(path);
  };

  const backdropClickHandler = () => {
    if (!appid || loading) return;
    const path = routes.protected.applications.app.wallet.link(appid);
    navigate(path);
  };

  const { counter, start, reset } = useCountDown(10);

  useEffect(() => {
    if (!appid) return;

    if (counter === 10 && !account?.payment_status) {
      start();
      setLoading(true);
      dispatch(fundWalletPolling(appid))
        .unwrap()
        .then((res) => {
          if (res?.account_name) {
            setAccount(res);
            transactionSuccessfull(res);
          }
        })
        .catch(() => {
          transactionFailed();
        });
    }
    if (counter === 0) {
      reset();
    }
  }, [counter]); //eslint-disable-line

  // useEffect(() => {
  //   if (!appid) return;
  //   switch (response?.message_type) {
  //     case "transaction_initiated":
  //       if (response.status === "error") {
  //         transactionInitiatedError();
  //       } else {
  //         setStage(true);
  //       }
  //       break;
  //     case "funding_not_accepted":
  //       dispatch(listAppTransactions({ appid }))
  //         .unwrap()
  //         .then(transactionInitiatedError);
  //       break;
  //     case "funding_accepted":
  //       setStage(true);
  //       dispatch(
  //         listAppTransactions({
  //           appid,
  //         })
  //       )
  //         .unwrap()
  //         .then(() =>
  //           transactionInitiatedSuccess(response.data?.txn_reference)
  //         );
  //       break;

  //     default:
  //       break;
  //   }
  // }, [JSON.stringify(response)]); //eslint-disable-line

  return (
    <SideModal
      onclickBackDrop={backdropClickHandler}
      className="flex flex-col gap-y-20 p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <div className="flex items-center justify-center">
        <img
          className="h-60 w-60 object-contain text-center"
          src={loadingGif}
          alt="loading"
        />
      </div>

      <div className="grid grid-cols-[max-content_1fr_max-content_1fr_max-content] items-start">
        <div className="col-start-1 col-end-2  flex flex-col items-center gap-4">
          <div className="flex h-9 w-9 items-center justify-center rounded-full bg-green-50 text-xs text-white">
            1
          </div>
          <p className="col-start-1 col-end-4 w-28 text-center text-sm text-green-50">
            {PageDictionary.process_initiated}
          </p>
        </div>
        <div className="col-start-2 col-end-3 h-9 w-full">
          <span className="relative inline-block h-1 w-full flex-1 bg-gray-460">
            <span
              className={mergeClassName(
                "absolute top-0 left-0 inline-block h-full bg-green-50",
                stage > 1 ? "w-full animate-none" : "w-1/2 animate-slide_left"
              )}
            />
          </span>
        </div>
        <div className="col-start-3 col-end-4 flex flex-col items-center gap-y-4">
          <div
            className={mergeClassName(
              "flex h-9 w-9 items-center justify-center rounded-full text-xs text-white",
              stage === 2 ? "bg-green-50" : "bg-gray-460"
            )}
          >
            2
          </div>
          <p
            className={mergeClassName(
              "w-32 justify-self-end text-center text-sm ",
              stage === 2 ? "text-green-50" : ""
            )}
          >
            {PageDictionary.fetching_account}
          </p>
        </div>
        <div className="col-start-4 col-end-5 h-9 w-full">
          <span className="relative inline-block h-1 w-full flex-1 bg-gray-460">
            <span
              className={mergeClassName(
                "absolute top-0 left-0 inline-block h-full",
                stage < 2 || stage > 2
                  ? "w-full animate-none "
                  : "w-1/2 animate-slide_left bg-green-50"
              )}
            />
          </span>
        </div>
        <div className="col-start-5 col-end-6 flex flex-col items-center gap-y-4">
          <div
            className={mergeClassName(
              "flex h-9 w-9 items-center justify-center rounded-full text-xs text-white",
              stage === 3 ? "bg-green-50" : "bg-gray-460"
            )}
          >
            3
          </div>
          <p
            className={mergeClassName(
              "w-32 justify-self-end text-center text-sm ",
              stage === 3 ? "text-green-50" : ""
            )}
          >
            {PageDictionary.awaiting_confirmation}
          </p>
        </div>
      </div>

      <div>
        <h2 className="text-center text-3236 font-bold">
          {PageDictionary.processing}
        </h2>
        <p className="mx-auto mt-6 max-w-[16rem] text-center text-sm font-normal text-gray-410">
          {PageDictionary.routing}
        </p>
      </div>
    </SideModal>
  );
}

export default PaymentPrcocessing;
