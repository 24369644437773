import { useParams, useSearchParams } from "react-router-dom";
import {
  DropDownItem,
  DropdownMenu,
  DropDownMenuContent,
  DropdownMenuTrigger,
} from "@/common/components/CustomDropDown";
import { mergeClassName } from "@/common/utils/helpers";

const filterlist = [
  {
    name: "Total TX freqiesncy",
    value: "max_daily_txn_freq",
  },
  {
    name: "Threshold amount  ( Per day)",
    value: "max_daily_txn_amount",
  },
  {
    name: "Threshold amount  ( Per time)",
    value: "max_transaction_amount",
  },
  {
    name: "Time In between two tx",
    value: "allowed_duration_btw_transaction",
  },
  {
    name: "Quarantime Tx amount",
    value: "quarantine_transaction_amount",
  },
  {
    name: "Quarantine Tx frequency",
    value: "quarantine_transaction_freq",
  },
];

function RiskRecordQuery() {
  const { appid, policyid } = useParams();
  const [params, setParams] = useSearchParams();
  const filterParam = params.get("filter");
  const policy = params.get("policy");
  const selected = filterlist.find((itm) => itm.value === filterParam);

  const updateQueryHandler = (filter: string) => {
    if (!appid || !policyid || !policy) return;
    if (filter) {
      setParams({ filter, policy });
    } else {
      setParams({ policy });
    }
  };

  return (
    <div className="flex items-center gap-x-5">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="h-12 min-w-[14rem] cursor-pointer appearance-none rounded-xl border border-gray-340 bg-arrowdown  bg-[90%]  bg-no-repeat py-3 pl-6 pr-11 text-start text-base font-bold text-gray-60">
            {!selected?.name ? "Filter by:" : selected?.name}
          </button>
        </DropdownMenuTrigger>
        <DropDownMenuContent className="text-blue_gray-10 z-10 flex w-72 cursor-pointer flex-col rounded-lg bg-white font-bold shadow-pool_card">
          {filterlist.map((list) => (
            <DropDownItem key={list.value} className="group w-full">
              <button
                onClick={() => updateQueryHandler(list.value)}
                className={mergeClassName(
                  "flex w-full items-center gap-x-2 px-4 py-2 text-sm group-first:pt-3.5 group-last:pb-7 group-only:py-4",
                  selected?.value === list.value ? "text-red-10" : "text-black"
                )}
              >
                <span>{list.name}</span>
              </button>
            </DropDownItem>
          ))}
        </DropDownMenuContent>
      </DropdownMenu>
      <button
        onClick={() => updateQueryHandler("")}
        className={mergeClassName(
          !selected?.name ? "cursor-[inherit] opacity-0" : ""
        )}
      >
        reset
      </button>
    </div>
  );
}

export default RiskRecordQuery;
