import React from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "@/common/components/CustomButton";
import CenterModal from "@/common/components/CustomModal/CenterModal";
import routes from "@/common/routes";
import { ignore2FaPrompt } from "@/common/service/storage";
import { iHandleSubmit } from "../../types";
import Language from "@/common/utils/language/en";

const PageDictionary = Language.protected.settingsModal.TwoFaPrompt;

function TwoFactorAuthPrompt() {
  const navigate = useNavigate();
  const location: Location = useLocation()?.state?.background;

  const cancelHandler = () => {
    const path = location.pathname;
    ignore2FaPrompt();
    navigate(path, { replace: true });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    const path = routes.protected.settings.two_factor_toggle;
    navigate(path, {
      replace: true,
      state: { background: location, prompt: true },
    });
  };

  return (
    <CenterModal handleClose={cancelHandler} blurBg>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
        autoComplete="off"
      >
        <h3 className="self-start text-2xl font-semibold text-black">
          {PageDictionary.warn_text}
        </h3>
        <p className="text-left">{PageDictionary.form_label}</p>
        <div className="flex flex-1 items-center justify-end gap-x-9 self-end">
          <CustomButton type="button" variant onClick={cancelHandler}>
            {PageDictionary.cancel}
          </CustomButton>
          <CustomButton>{PageDictionary.submit}</CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default TwoFactorAuthPrompt;
