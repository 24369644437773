import { useNavigate, useParams } from "react-router-dom";
import SideModal from "@/common/components/CustomModal/SideModal";
import routes from "@/common/routes";
import loading from "../../../../../../static/images/loading.gif";
import Language from "@/common/utils/language/en";

const { AwaitingConfirmation: PageDictionary } =
  Language.protected.walletModals.funding;

function AwaitingConfirmation() {
  const { appid } = useParams();
  const navigate = useNavigate();

  const handleBackDrop = () => {
    if (!appid) return;
    navigate(routes.protected.applications.app.wallet.link(appid), {
      replace: true,
    });
  };

  // const completeRequestHandler = () => {
  //   if (!appid) return;
  //   const background = state?.background;
  //   const path = routes.protected.applications.app.wallet.fund.success.link(appid);
  //   navigate(path, { state: { background } });
  // };

  // useEffect(() => {
  //   let timer = setTimeout(() => {
  //     completeRequestHandler();
  //   }, 10000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []); //eslint-disable-line

  return (
    <SideModal
      onclickBackDrop={handleBackDrop}
      className="flex flex-col gap-y-10 p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <div className="flex items-center justify-center">
        <img
          className="h-60 w-60 object-contain text-center"
          src={loading}
          alt="loading"
        />
      </div>

      <div className="flex flex-1 flex-col gap-y-2">
        <h2 className="text-center text-3236 font-bold">
          {PageDictionary.title}
        </h2>
        <p className="text-center  text-sm font-normal text-gray-410">
          {PageDictionary.sub_title}
        </p>
        {/* <p className="flex flex-1 items-center justify-center gap-x-3 text-lg">
          Payment will be Confirmed:{" "}
          <span className="text-2xl font-bold leading-none text-red-60">
            00:56:34
          </span>
        </p> */}
      </div>
    </SideModal>
  );
}

export default AwaitingConfirmation;
