import React from "react";
import Language from "@/common/utils/language/en";
import CustomInput from "@/common/components/CustomInput";
import CustomButton from "@/common/components/CustomButton";
import CustomBarCode from "../CustomBarCode";
import { iHandleChange } from "../../types";
import { useEffectOnce } from "@/common/hooks";

const { authorize: PageDictionary } = Language.protected.onboardingModals;

type Props = {
  generated_signature_id: string;
  initials: string;
  generateSignatureHandler: VoidFunction;
  signatureContainerRef: React.LegacyRef<HTMLDivElement>;
  show_barcode: boolean;
  changeHandler: iHandleChange;
  cleanUpHandler: VoidFunction;
  showBarcodeHandler: VoidFunction;
  isGeneratingId: boolean;
};

function AuthorityInitials({
  generateSignatureHandler,
  generated_signature_id,
  initials,
  signatureContainerRef,
  changeHandler,
  cleanUpHandler,
  show_barcode,
  showBarcodeHandler,
  isGeneratingId,
}: Props) {
  useEffectOnce(() => {
    return () => {
      cleanUpHandler();
    };
  });

  return (
    <>
      <div className="flex items-end gap-x-3">
        <CustomInput
          labelClassName="text-xs"
          label={PageDictionary.formLabels.enter_initials}
          name="initials"
          value={initials}
          onChange={changeHandler}
          maxLength={20}
          required
        />
        <CustomButton
          onClick={showBarcodeHandler}
          isloading={isGeneratingId}
          disabled={initials.length === 0 || generated_signature_id !== ""}
          type="button"
          className=" px-2 py-4"
        >
          {PageDictionary.formLabels.generate_signature}
        </CustomButton>
      </div>

      {show_barcode ? (
        <CustomBarCode
          label={PageDictionary.formLabels.signature}
          value={initials}
          itmRef={signatureContainerRef}
          generateSignatureHandler={generateSignatureHandler}
        />
      ) : null}
    </>
  );
}

export default AuthorityInitials;
