import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import routes from "@/common/routes";
import AmountToPay from "./AmountToPay";
import { removeUnderScoreAddSpace } from "@/common/utils/helpers";
import CustomButton from "@/common/components/CustomButton";
import Language from "@/common/utils/language/en";

const { FundBankForm: PageDictionary } =
  Language.protected.walletModals.funding;

function NigerianForm() {
  const { appid, txnid } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = state?.background;
  const amount = state?.amount as string;
  const currency = state?.currency as string;
  const account = (state?.account || {}) as Record<string, string>;

  const submitHandler = () => {
    if (!appid || !txnid) return;
    const path =
      routes.protected.applications.app.wallet.fund.payment_processing.link(
        appid,
        2
      );
    navigate(path, {
      state: { background: location },
    });
  };

  const value = `${currency} ${amount}`;
  return (
    <div className="flex flex-col gap-y-9 pt-11">
      <AmountToPay amount={amount} currency={currency} />

      <ol className="list-inside list-decimal space-y-4 text-[#525869]">
        <li className="text-inherit">
          <span className="text-sm">Open your Bank App</span>
        </li>
        <li className="space-y-6 text-inherit">
          <span className="text-sm">{`Pay exact amount ${value} into account details below .`}</span>
          <div className="flex flex-col gap-y-5 rounded border border-dashed border-[#384EB74D] bg-[#F8F8FF] p-9">
            <table className="w-full text-left">
              <thead className="text-sm text-gray-410">
                <tr>
                  {Object.keys(account)?.map((key) => {
                    return (
                      <th key={key} className="font-normal capitalize">
                        {removeUnderScoreAddSpace(key)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="text-base text-base-20">
                <tr>
                  {(Object?.keys(account) as Array<keyof typeof account>)?.map(
                    (key) => {
                      return (
                        <td key={key} className="font-normal capitalize">
                          {account?.[key]}
                        </td>
                      );
                    }
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        {/* <li className="space-y-2.5 text-inherit">
          <span className="text-sm">
            Transfer money from the account of “Allowed users” with active KYC
            below.{" "}
            <span className="block italic text-red-10">
              Third Party Funding is not allowed.
            </span>
          </span>
          <div className="flex flex-col gap-y-5 rounded border border-dashed border-[#384EB74D] bg-[#F8F8FF] py-3 px-4 ">
            <table className="w-full text-left">
              <thead className="text-sm text-gray-410">
                <tr>
                  <th>Account Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="text-base text-base-20">
                <tr>
                  <td>Charles Avis A</td>
                  <td>
                    <div className="w-fit rounded bg-[#12C089] py-1.5 px-4 text-white">
                      Active KYC
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Teslim Ayantola</td>
                  <td>
                    <div className="w-fit rounded bg-[#12C089] py-1.5 px-4 text-white">
                      Active KYC
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-sm text-[#525869]">
            Want to Add another “Allowed user”?{" "}
            <span className="font-bold">Start KYC here</span>
          </p>
        </li> */}
        <li className="text-inherit">
          <span className="text-sm">
            Click “I have Paid”. We will confirm the payment and add the funds
            to your wallet.
          </span>
        </li>
      </ol>

      <CustomButton
        // disabled={inData.image.length === 0}
        className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        onClick={submitHandler}
      >
        {PageDictionary.form.submit_btn}
      </CustomButton>
    </div>
  );
}

export default NigerianForm;
