import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import SideModal from "@/common/components/CustomModal/SideModal";
import ModalTitleHeader from "@/common/components/ModalTitleHeader";
import CustomInput from "@/common/components/CustomInput";
import CustomButton from "@/common/components/CustomButton";
import ModalNavLinks from "../../../../components/ModalNavLinkFunding";
// import { walletActions } from "../../../../store/slice";
import { FormatNumber } from "@/common/utils/helpers";
import { iHandleChange } from "../../../../types";
import {
  addCommas,
  removeCommas,
  removeNonNumeric,
} from "@/common/utils/helpers";
import routes from "@/common/routes";
import Language from "@/common/utils/language/en";
import { fundWallet } from "../../../../store/thunk";
import ShareholdersWithKyc from "../../../../components/ShareholdersWithKyc";

const { FundWalletForm: PageDictionary } =
  Language.protected.walletModals.funding;

const initialstate = {
  message: "",
  type: "funding_amount",
  shareholder_id: "",
};

function FundWalletForm() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation().state?.background;
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  // const { isEstablishingConnection, isConnected } = useAppSelector(
  //   (state) => state.wallet.socket
  // );
  const is_bank = useAppSelector(
    (state) => state.app.tabApps.find((app) => app._id === appid)?.is_bank
  );

  const exchange_rate = useAppSelector(
    (state) => state.wallet.exchange_rate.data
  );

  const usdValue = exchange_rate?.fund_rate?.from
    ? parseInt(
        removeCommas(inData.message) ? removeCommas(inData.message) : "0"
      ) / exchange_rate?.fund_rate?.rate
    : 0;

  const rate = FormatNumber(usdValue, true);

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value } = e.target;
    setInData((prev) => ({
      ...prev,
      message: addCommas(removeNonNumeric(value)),
    }));
  }, []);
  const radioChangeHandler: iHandleChange = (e) => {
    const { value } = e.target;
    setInData((prev) => ({
      ...prev,
      shareholder_id: value,
    }));
  };

  const backDrop = () => {
    if (!appid || loading) return;
    let path = routes.protected.applications.app.wallet.link(appid);
    navigate(path);
  };

  // start socket for wallet funding
  useEffect(() => {
    if (!appid) return;
    // let path = "";

    // if (isConnected === true) {
    //   path =
    //     routes.protected.applications.app.wallet.fund.payment_processing.link(
    //       appid
    //     );
    //   dispatch(
    //     walletActions.submitMessage({
    //       content: { ...inData, message: removeCommas(inData.message) },
    //     })
    //   );
    //   navigate(path, {
    //     state: { background: location, amount: inData.message },
    //   });
    // }
    if (!exchange_rate?.fund_rate.from) {
      backDrop();
    }
  }, []); // eslint-disable-line

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!appid) return;
    const amount = removeCommas(inData.message);
    setLoading(true);
    dispatch(
      fundWallet({
        appid,
        data: { amount, shareholder_id: inData.shareholder_id },
      })
    )
      .unwrap()
      .then(() => {
        const path =
          routes.protected.applications.app.wallet.fund.payment_processing.link(
            appid,
            1
          );
        navigate(path, {
          state: {
            background: location,
            amount: inData.message,
            currency: exchange_rate?.fund_rate?.from,
          },
        });
      });

    // dispatch(
    //   walletActions.startConnecting({ app_id: appid, txn_reference: null })
    // );
  };

  return (
    <SideModal
      onclickBackDrop={backDrop}
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <ModalTitleHeader
        title={PageDictionary.header.title}
        subtitle={PageDictionary.header.subtitle}
      />
      <form className="flex flex-col gap-y-10 pt-11" onSubmit={submitHandler}>
        <ModalNavLinks is_bank={is_bank} />
        <div className="relative">
          <div className="relative">
            <span className="absolute bottom-4 left-4 z-[2] uppercase">
              {exchange_rate?.fund_rate?.from}
            </span>
            <CustomInput
              label={PageDictionary.form.amount_label}
              className="pl-[4.25rem]"
              value={inData.message}
              onChange={changeHandler}
            />
          </div>
          <p className="absolute right-5">
            {PageDictionary.form.amount_dollar}{" "}
            <span className="text-red-10">{rate}</span>
          </p>
        </div>

        <p className="text-sm text-gray-400">{PageDictionary.form.warn_text}</p>

        <ShareholdersWithKyc
          value={inData.shareholder_id}
          disabled={loading}
          radioChangeHandler={radioChangeHandler}
        />

        <CustomButton
          disabled={
            inData.message === "" ||
            inData.shareholder_id === "" ||
            usdValue < 10
          }
          // isloading={isEstablishingConnection}
          isloading={loading}
          className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        >
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default FundWalletForm;
