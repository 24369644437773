import React from "react";
import { useForm } from "react-hook-form";
import { cardFormType, resolver } from "./schema";
import {
  defaultValueHandler,
  optionsHandler,
  transferFormFeilds,
} from "./helper";
import { Form, FormItem } from "@/common/components/Form";
import { Input } from "@/common/components/Form/elements/Input";
import Select from "@/common/components/Form/elements/Select";
import CustomButton from "@/common/components/CustomButton";
import { useAppDispatch } from "@/common/hooks";
import { differenceHandler } from "@/common/utils/helpers";
import {
  createPricingPolicy,
  updatePricingPolicy,
} from "@/module/applications/store/thunk";
import OtherInfo from "../OtherInfo";
import { PricingPolicyFormProps } from "../types";

function CardForm({
  initialData,
  otherData,
  loading,
  completedHandler,
}: PricingPolicyFormProps) {
  const form = useForm<cardFormType>({
    resolver,
    defaultValues: defaultValueHandler(initialData),
  });
  const dispatch = useAppDispatch();

  const value_type = form.watch("value_type");

  const submitHandler = (data: cardFormType) => {
    const payload = differenceHandler(data, initialData);

    const action = initialData?._id
      ? updatePricingPolicy({
          pricingid: initialData?._id,
          ...otherData,
          ...payload,
        })
      : createPricingPolicy({
          ...otherData,
          ...payload,
        });

    dispatch(action).unwrap().finally(completedHandler);
  };

  return (
    <Form<cardFormType>
      {...form}
      form={form}
      onSubmit={submitHandler}
      className="grid grid-cols-2 gap-x-7 gap-y-8 lg:pr-7"
    >
      {transferFormFeilds.map((field) => (
        <FormItem
          key={field.name}
          name={field.name}
          label={field.label}
          required={field?.rules?.required}
          classNames={{
            label: "text-sm leading-6 text-[#090914] mb-2.5",
          }}
          render={(otherProps) => (
            <>
              {field.type === "select" ? (
                <Select
                  placeholder={field.placeHolder}
                  {...otherProps}
                  data={optionsHandler(field.name)}
                />
              ) : (
                <Input
                  placeholder={field?.placeHolder}
                  {...otherProps}
                  prefix={field?.prefixIcon}
                  suffix={
                    value_type === "FLAT" && field?.suffixIcon
                      ? "$"
                      : field?.suffixIcon
                  }
                />
              )}
              <OtherInfo data={field?.otherInfo} />
            </>
          )}
        />
      ))}

      <CustomButton
        className="col-start-1 justify-self-start"
        isloading={loading}
        disabled={!form.formState.isDirty}
      >
        Submit
      </CustomButton>
    </Form>
  );
}

export default CardForm;
