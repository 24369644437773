import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "@/common/components/CustomButton";
import CenterModal from "@/common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import { removeShareholder } from "../../store/thunk";
import Language from "@/common/utils/language/en";

const { confirm_shareholder_action: PageDictionary } =
  Language.protected.compalinaceModals;

function ConfirmShareholderAction() {
  const { shareid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.compliance.shareholders);

  const cancelHandler = () => {
    if (loading) return;
    navigate(location.state.background.pathname, {
      replace: true,
      state: null,
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!shareid) return;
    dispatch(removeShareholder(shareid)).unwrap().then(cancelHandler);
  };

  return (
    <CenterModal className="pt-26 pb-32" handleClose={cancelHandler}>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-blue_gray-10 text-2xl">{PageDictionary.title}</h3>
        <p className="text-sm text-red-40">{PageDictionary.subtitle}</p>
        <div className="flex items-center gap-x-9">
          <CustomButton variant onClick={cancelHandler}>
            {PageDictionary.btn_actions.cancel}
          </CustomButton>
          <CustomButton isloading={loading}>
            {PageDictionary.btn_actions.confirm}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default ConfirmShareholderAction;
