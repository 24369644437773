import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import CustomBanner from "@/common/components/CustomBanner";
import banner from "../../../../static/images/banners/virtualcards.png";
import commingSoon from "../../../../static/images/comingsoon.png";

function VirtualCardLayout() {
  const [isComingSoon, setIsComingSoon] = useState(false);
  const clickHandler = () => {
    setIsComingSoon((prev) => !prev);
  };
  return (
    <>
      {isComingSoon ? (
        <div className="h-full">
          <CustomBanner
            headline="Give your customers the ability to spend anywhere"
            sub="Offer Virtual Cards in your app."
            img={banner}
            classNames={{
              container: "bg-virtual_card",
              texts: "text-white",
            }}
          />
          <div className="mt-40 flex h-auto items-center justify-center">
            <button onClick={clickHandler} className="text-2226 text-base-20">
              <img
                alt="coming soon"
                className="mx-auto w-3/5"
                src={commingSoon}
              />
            </button>
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default VirtualCardLayout;
