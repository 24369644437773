import SideModal from "@/common/components/CustomModal/SideModal";
import ModalTitleHeader from "@/common/components/ModalTitleHeader";
import React from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TransferForm from "./components/TransferForm";
import CardForm from "./components/CardForm";
import KycForm from "./components/KycForm";
import CustomButton from "@/common/components/CustomButton";
import { useAppDispatch, useAppSelector, useEffectOnce } from "@/common/hooks";
import { appActions } from "@/module/applications/store/slice";
import { toSentenceCase } from "@/common/utils/helpers";

const fuzzyNames = (value: string) => {
  if (value === "BANK_TRANSFER") {
    return "GLOBAL_TRANSFER";
  }
  if (value === "WALLET_TRANSFER") {
    return "FINSWICH_TRANSFER";
  }

  return value;
};

export function Pricing() {
  const [params] = useSearchParams();
  const pricing_type: string = params.get("type") || "";
  const sub_type: string = params.get("feature") || "";
  const appid = useParams()?.appid || "";
  const pageTitle = toSentenceCase(fuzzyNames(sub_type)?.split("_")?.join(" "));

  const otherData = {
    appid,
    pricing_type,
    sub_type,
  };

  const { data, loading } = useAppSelector(
    (state) => state.app.plugins.selected_model
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const pathname: string = state?.background?.pathname;

  const backDropHandler = () => {
    if (!appid || !pathname) return;
    navigate(pathname, { replace: true });
  };

  useEffectOnce(() => {
    return () => {
      dispatch(appActions.removeSelectedPricingPolicy());
    };
  });

  return (
    <SideModal
      className="flex flex-col space-y-9 p-8 md:pt-9 md:pl-15 md:pr-28 md:pb-9"
      onclickBackDrop={backDropHandler}
    >
      <div className="flex items-center justify-between">
        <button onClick={backDropHandler}>
          <span className="text-3xl">&#8592;</span>
        </button>
        <CustomButton className="flex h-auto items-center bg-transparent py-1 text-sm font-bold text-[#1E3F52]">
          View Pricing Catalog <span className="text-3xl">&rarr;</span>
        </CustomButton>
      </div>
      <ModalTitleHeader
        classNames={{
          title: "capitalize",
        }}
        title={`${pageTitle} Pricing`}
        subtitle={`Manage how much your users get charged for`}
        subTitleOther={<span className="capitalize">{pageTitle}</span>}
      />
      {pricing_type === "GLOBAL_TRANSFERS" ? (
        <TransferForm
          initialData={data}
          otherData={otherData}
          loading={loading}
          completedHandler={backDropHandler}
        />
      ) : null}
      {pricing_type === "CARD" ? (
        <CardForm
          initialData={data}
          otherData={otherData}
          loading={loading}
          completedHandler={backDropHandler}
        />
      ) : null}
      {pricing_type === "KYC" ? (
        <KycForm
          initialData={data}
          otherData={otherData}
          loading={loading}
          completedHandler={backDropHandler}
        />
      ) : null}
    </SideModal>
  );
}
