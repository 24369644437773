import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "@/common/components/CustomButton";
import CustomInput from "@/common/components/CustomInput";
import SideModal from "@/common/components/CustomModal/SideModal";
import { Select, SelectItem } from "@/common/components/CustomSelect";
import ModalTitleHeader from "@/common/components/ModalTitleHeader";
import { useAppDispatch, useAppSelector } from "@/common/hooks";
import routes from "@/common/routes";
import { addNotificationConfig } from "../../../store/thunk";
import { iHandleChange } from "../../../types";

const initialstate = {
  type: "",
  notification_method: "",
  schedule: "",
  notify_to: "",
};

const notification_type = [
  {
    value: "MAX_DAILY_TXN_FREQ",
    name: "MAX DAILY TXN FREQ",
  },
  {
    value: "MAX_DAILY_TXN_AMOUNT",
    name: "MAX DAILY TXN AMOUNT",
  },
  {
    value: "MAX_TRANSACTION_AMOUNT",
    name: "MAX TRANSACTION AMOUNT",
  },
  {
    value: "ALLOWED_DURATION_BTW_TRANSACTION",
    name: "ALLOWED DURATION BTW TRANSACTION",
  },
  {
    value: "QUARANTINE_TRANSACTION_FRQ",
    name: "QUARANTINE TRANSACTION FRQ",
  },
  {
    value: "QUARANTINE_TRANSACTION_AMOUNT",
    name: "QUARANTINE TRANSACTION AMOUNT",
  },
];
export const notification_method = [
  {
    name: "Email",
    value: "EMAIL",
  },
  {
    name: "API",
    value: "API",
  },
];
export const notification_schedule = [
  {
    name: "Daily",
    value: "DAILY",
  },
  {
    name: "Weekly",
    value: "WEEKLY",
  },
  {
    name: "Monthly",
    value: "MONTHLY",
  },
];

export function CreateNotification() {
  const { appid, policyid } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const pathname: string = state?.background?.pathname;
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.app.policy.notification);

  //   const [buttonText, setButtonText] = useState("Save");

  //   const [edit, setEdit] = useState(false);

  const backDropHandler = () => {
    if (!appid || !policyid || loading) return;
    const path =
      routes.protected.applications.app.risk_control.risks.notification_config.link(
        appid,
        policyid
      );
    navigate(path, { replace: true });
  };

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const selectHandler = (name: string, value: string) => {
    // const val = JSON.parse(value);
    setInData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!appid || !policyid) return;

    const req = {
      appid,
      pid: policyid,
      data: inData,
    };
    dispatch(addNotificationConfig(req))
      .unwrap()
      .finally(() => navigate(pathname, { replace: true }));
  };
  return (
    <SideModal
      className="flex flex-col p-8 md:pt-22 md:pl-15 md:pr-28 md:pb-9"
      onclickBackDrop={backDropHandler}
    >
      <ModalTitleHeader title="Add Configuration" subtitle="" />
      <form
        onSubmit={submitHandler}
        className="flex flex-1 flex-col  pt-9 pb-9"
      >
        <div className="flex flex-col gap-y-5">
          <Select
            label="Type"
            labelClassName="mb-3 font-bold inline-block"
            className="h-12"
            onChange={(e) => selectHandler("type", e)}
            disabled={loading}
            value={inData.type}
            placeholder="Select a Type"
          >
            {notification_type.map((policy, id) => (
              <SelectItem key={id} value={policy.value}>
                <span>{policy.name}</span>
              </SelectItem>
            ))}
          </Select>
          <Select
            label="Notification method"
            labelClassName="mb-3 font-bold inline-block"
            className="h-12"
            onChange={(e) => selectHandler("notification_method", e)}
            disabled={loading}
            value={inData.notification_method}
            placeholder="Select a Notification method"
          >
            {notification_method.map((policy, id) => (
              <SelectItem key={id} value={policy.value}>
                <span>{policy.name}</span>
              </SelectItem>
            ))}
          </Select>
          <Select
            label="Schedule"
            labelClassName="mb-3 font-bold inline-block"
            className="h-12"
            onChange={(e) => selectHandler("schedule", e)}
            disabled={loading}
            value={inData.schedule}
            placeholder="Select a Schedule"
          >
            {notification_schedule.map((policy, id) => (
              <SelectItem key={id} value={policy.value}>
                <span>{policy.name}</span>
              </SelectItem>
            ))}
          </Select>
          <CustomInput
            className="h-12 py-1.5"
            containerClassName=""
            labelClassName="mb-3"
            label="Notify to"
            name="notify_to"
            autoComplete="new-password"
            value={inData.notify_to}
            type={
              inData.notification_method === "EMAIL"
                ? "email"
                : inData.notification_method === "API"
                ? "url"
                : "text"
            }
            onChange={changeHandler}
            disabled={loading || inData.notification_method === ""}
          />
        </div>
        <div className="flex flex-1 flex-col items-end justify-end">
          <CustomButton
            disabled={Object.values(inData).includes("")}
            isloading={loading}
            className="mt-4 self-start rounded-lg py-3 px-12 text-sm leading-6"
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </SideModal>
  );
}
