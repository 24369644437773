import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessSideModal from "@/common/components/CustomModal/SuccessSideModal";
import Language from "@/common/utils/language/en";

const { SettingsAddedSuccess: PageDictionary } =
  Language.protected.walletModals.withdrawalSetting;

function SettingsAddedSuccess() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const goBackToBackground = () => {
    navigate(state?.background?.pathname, { replace: true });
  };
  return (
    <SuccessSideModal
      action={goBackToBackground}
      title={PageDictionary.title}
      subtitle={PageDictionary.subtitle}
      cta={PageDictionary.close}
    />
  );
}

export default SettingsAddedSuccess;
