import { FieldTypes } from "@/common/components/Form/type";
import { cardFormType } from "./schema";
import { PricingPolicyType } from "@/module/applications/types";

export const transferFormFeilds: FieldTypes<cardFormType>[] = [
  {
    name: "create_card",
    label: "Create Card",
    otherInfo: "Cost of creating card",
    prefixIcon: "$",
  },
  {
    name: "card_withdrawal",
    label: "Card Withdrawal",
    otherInfo: "Cost of withdrawing from card",
    prefixIcon: "$",
  },
  {
    name: "card_funding",
    label: "Card Funding",
    otherInfo: "Amount to charge on card funding",
    prefixIcon: "$",
  },
  {
    name: "card_maintenance",
    label: "Card Maintenance",
    otherInfo: "Monthly card maintenance fee",
    prefixIcon: "$",
  },
  {
    name: "value_type",
    label: "Card Transaction",
    otherInfo: "Select fee type (flat or percentile)",
    type: "select",
  },
  {
    name: "markup",
    label: "Card Transaction",
    otherInfo: "Amount to charge on successful txn",
    suffixIcon: "%",
  },
  {
    name: "insufficient_balance_penalty",
    label: "Insufficient Balance Penalty",
    otherInfo:
      "This will be charged as penalty for insufficient balance more than 2 times",
    suffixIcon: "%",
  },
];

export const defaultValues: cardFormType = {
  create_card: 0,
  card_withdrawal: 0,
  card_funding: 0,
  card_maintenance: 0,
  value_type: "PERCENTAGE",
  markup: 0,
  insufficient_balance_penalty: 0,
};

const transactionTypeOptions = [
  { label: "Percentile", value: "PERCENTAGE" },
  { label: "Flat", value: "FLAT" },
];

export const optionsHandler = (name: string) => {
  if (name === "value_type") {
    return transactionTypeOptions;
  }

  return [];
};

export const defaultValueHandler = (data?: Partial<PricingPolicyType>) => {
  return {
    ...(data || defaultValues),
  };
};
