import * as React from "react";
import { FaRegEyeSlash, FaRegEye, FaSearch } from "react-icons/fa";
import { useFormField } from "..";
import { mergeClassName } from "@/common/utils/helpers";

type InputClassName = "container" | "input" | "error";

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "prefix"> {
  className?: string;
  currency?: boolean;
  classNames?: Partial<Record<InputClassName, string>>;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, classNames, name, type, currency, suffix, prefix, ...props },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    const [inputValue, setInputValue] = React.useState<string>("");
    const { error } = useFormField();

    const handlePasswordToggle = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.preventDefault();
      setIsPasswordVisible((prev) => !prev);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (currency) {
        // Remove non-numeric characters except dot
        value = value.replace(/[^0-9]/g, "");
        setInputValue(new Intl.NumberFormat("en-US").format(Number(value)));
      } else {
        setInputValue(value);
      }

      // Call the original onChange handler if provided
      if (props.onChange) {
        // Pass the original numeric value to onChange event
        const event = {
          ...e,
          target: {
            ...e.target,
            value: currency ? value.replace(/[^0-9]/g, "") : value,
          },
        };
        props.onChange(event as React.ChangeEvent<HTMLInputElement>);
      }
    };

    return (
      <div
        className={mergeClassName(
          "flex w-full flex-col",
          classNames?.container || ""
        )}
      >
        <div className="relative w-full">
          {prefix && (
            <div className="absolute left-3.5 top-1/2 -translate-y-1/2">
              {prefix}
            </div>
          )}
          <input
            className={mergeClassName(
              "h-14 w-full rounded-xl border bg-inputbg py-3 pl-4 text-base leading-none text-black placeholder:text-base placeholder:text-gray-320",
              prefix || type?.includes("search") ? "pl-9" : "",
              suffix || type?.includes("password") ? "pr-12" : "",
              classNames?.input || className,
              error ? "border-red-10" : ""
            )}
            ref={ref}
            name={name}
            type={isPasswordVisible ? "text" : type}
            value={inputValue}
            onChange={handleChange}
            {...props}
          />
          {suffix && (
            <div className="absolute right-3.5 top-1/2 -translate-y-1/2">
              {suffix}
            </div>
          )}

          {type?.includes("search") && (
            <div className="absolute left-3.5 top-1/2 -translate-y-1/2 text-black">
              <FaSearch />
            </div>
          )}
          {type?.includes("password") && (
            <button
              className="absolute right-3 top-[33%]"
              onClick={handlePasswordToggle}
            >
              {!isPasswordVisible ? (
                <FaRegEye className="text-sm text-black hover:text-gray-900" />
              ) : (
                <FaRegEyeSlash className="text-sm text-black hover:text-gray-900" />
              )}
            </button>
          )}
        </div>
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
