import React from "react";
import { TbLoaderQuarter } from "react-icons/tb";
import { usePageTitle } from "@/common/hooks";
import CenterModal from "@/common/components/CustomModal/CenterModal";

function LogOut() {
  usePageTitle("Logout");
  return (
    <CenterModal className="bg-transparent pt-26 pb-32">
      <span className="animate-spin text-8xl text-white">
        <TbLoaderQuarter />
      </span>
    </CenterModal>
  );
}

export default LogOut;
